import {i18n} from "../../../../i18n";

export const changeLanguage = (_, lang) => {
    console.log(lang);
    try {
        document.cookie = `lang=${lang}`;
    } catch (e) {
        console.log('changeLang.js', e);
    }

    i18n.locale = lang;
};