export const tp = {
    'invoice': {
        'invoice': 'Nº da conta:',
        'sum-invoice': 'Valor da fatura:',
        'select-invoice': 'Selecione a moeda de pagamento',
        'coin-network': 'Moeda e rede:',
        'rate': 'Curso:',
        'sum-payment': 'Valor do pagamento:',
        'button': 'Pagamento',
        'search': 'Procurar',
    },
    'checkout': {
        'shop': 'Loja',
        'payment': 'Pagamento',
        'back_to_the_store': 'Voltar à loja',
        'to_make_a_payment': 'Para efetuar um pagamento, envie "{orderCurrency}" na rede "{orderAlias}" para o endereço especificado',
        'payment_completed': 'Pagamento executado',
        'the_payment_is_invalid': 'O pagamento não é válido',
        'payment_rejected': 'Pagamento rejeitado',
        'payment_pending': 'Pagamento em curso',
        'payment_init': 'Pagamento pendente',
        'lifetime_has_expired': 'O tempo de vida do pagamento expirou. Não envie moedas para o endereço especificado.',
        'contact_the_store_owner': 'O tempo de vida do pagamento expirou. Não envie moedas para o endereço especificado. Entre em contato com o proprietário da loja para obter um reembolso de moeda ou pagamento contínuo',
        'order': 'Garantir',
        'to_be_paid': 'Para pagar',
        'received': 'Recebido:',
        'description': 'Descrição:',
        'before_the_expiration_of_the_order': 'Prazo de pagamento:',
        'network': 'Rede:',
        'payment_address': 'Endereço de pagamento:',
        'payment_address_not_found': 'Endereço de pagamento não encontrado',
        'tag': 'Tag:',
        'contract_address': 'Endereço do contrato:',
        'send_to_this_address_only': 'Enviar apenas para este endereço',
        'make_sure_the_network_is_correct': 'Verifique se a rede está correta:',
        'network_is_correct': 'Verifique se a rede está correta:',
        'contract_address_matches': 'Verifique se o endereço do contrato corresponde à correspondência',
        'payment_page': 'Página de pagamento',
        'the_address_was_copied': 'Endereço copiado com êxito',
        'the_tag_was_copied': 'A tag foi copiada',
        'invoice_was_successfully_paid': 'O pedido foi pago com sucesso',
        'the_invoice_was_paid': 'O pedido foi pago pelo valor',
        'important_info': 'Informações importantes',
        'the_contract_address_does_not_exist_on_this_network': 'O endereço do contrato não existe nesta rede',
        'error': 'Erro',
        'time_hours': 'Horas',
        'time_minute': 'Ata',
        'time_second': 'Segundos',
        'sellers_address': 'O endereço do vendedor é verificado',
        'stored_table': 'Aqui estará o histórico de transações recebidas',
        'history_table': 'Histórico de transações recebidas',
        'full_info': 'Mostrar detalhes completos',
        'table_date': 'Data',
        'table_sum': 'Soma',
        'table_value': 'Moeda',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Valor e data',
        'payment_partially_paid': 'Parcialmente pago',
        'paid_timer': 'Pago',
        'expired_timer': 'O pagamento expirou',
    },
    'new_checkout': {
        'details': {
            'title': 'Detalhes',
            'order': 'Ordem',
            'date': 'Data',
            'description': 'Descrição',
        },
        'notification': 'Envie apenas tokens <b>{orderCurrency}</b> na rede <b>{orderNetwork}</b> para este endereço.',
        'payment_address': 'Endereço de pagamento',
        'verified_notification': "O endereço do vendedor foi verificado",
        'paid_amount': "Quantidade paga",
        'timer_notifications': {
            'remaining_time': 'Tempo restante para pagar',
            'completed': 'O pagamento foi concluído com sucesso',
            'expired': 'A vida útil do pagamento expirou',
        },
        'statuses': {
            'not_paid': 'Não pago',
            'overpaid': 'Pago em excesso',
            'partially_paid': 'Parcialmente pago',
            'fully_paid': 'Totalmente pago',
        },
        'history': {
            'title': 'Histórico de pagamentos',
            'columns': {
                'date': 'Data',
                'amount': 'Quantia',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Todas as suas transações enviadas para o endereço especificado estarão disponíveis nesta janela.',
            'ok': 'OK'
        },
        'cancel': 'Cancelar',
        'back_to_shop': 'De volta à compra'
    },
    'new_invoice': {
        'details': {
            title: 'Detalhes',
            invoice: 'Fatura',
            date: 'Data',
            description: 'Descrição'
        },
        'amount': 'Quantia',
        'btn_label': 'Pague com {selectedCurrencyNetwork}',
        'btn_label_empty': 'Escolha a moeda',
        'cancel': 'Cancelar',
        'search': 'Procurar...',
    },
    'languages': {
        'select': 'Selecione o idioma'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Página não encontrada',
            'page_not_found_text': 'Não parecemos encontrar a página que você está procurando',
        }
    }
};