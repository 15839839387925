export const lv = {
    'invoice': {
        'invoice': 'Konta numurs:',
        'sum-invoice': 'Konta summa:',
        'select-invoice': 'Izvēlieties maksājuma valūtu',
        'coin-network': 'Valūta un tīkls:',
        'rate': 'Nu:',
        'sum-payment': 'Maksājuma summa:',
        'button': 'Maksājums',
    },

    'checkout': {
        'shop': 'Rezultāts',
        'payment': 'Maksājums',
        'back_to_the_store': 'Atpakaļ uz veikalu',
        'to_make_a_payment': 'Nosūtiet  «{orderCurrency}» tīklā «{orderAlias}» , lai veiktu maksājumu uz norādīto adresi',
        'payment_completed': 'Maksājums pabeigts',
        'the_payment_is_invalid': 'Maksājums nav derīgs',
        'payment_rejected': 'Maksājums noraidīts',
        'payment_pending': 'Apmaksa apstrādē',
        'payment_init': 'Maksājums gaida',
        'lifetime_has_expired': 'Maksājuma termiņš ir beidzies. Nesūtiet monētas uz norādīto adresi.',
        'contact_the_store_owner': 'Maksājuma termiņš ir beidzies. Nesūtiet monētas uz norādīto adresi. Sazinieties ar veikala īpašnieku, lai atgrieztu monētas vai turpinātu maksājumu',
        'order': 'Pasūtiet',
        'to_be_paid': 'Maksāt',
        'received': 'Saņemts:',
        'description': 'Apraksts:',
        'before_the_expiration_of_the_order': 'Maksājuma derīguma termiņš:',
        'network': 'Tīkls:',
        'payment_address': 'Maksājumu adrese:',
        'payment_address_not_found': 'Maksājuma adrese nav atrasta',
        'tag': 'Taga:',
        'contract_address': 'Līguma adrese:',
        'send_to_this_address_only': 'Sūtiet tikai uz šo adresi',
        'make_sure_the_network_is_correct': 'Pārbaudiet, vai tīkls ir pareizs:',
        'network_is_correct': 'Pārbaudiet, vai tīkls ir pareizs:',
        'contract_address_matches': 'Pārliecinieties, vai līguma adrese atbilst',
        'payment_page': 'Maksājumu lapa',
        'the_address_was_copied': 'Adrese ir veiksmīgi nokopēta',
        'the_tag_was_copied': 'Atzīme ir nokopēta',
        'invoice_was_successfully_paid': 'Pasūtījums tika veiksmīgi apmaksāts',
        'the_invoice_was_paid': 'Pasūtījums tika apmaksāts',
        'important_info': 'Svarīga informācija',
        'the_contract_address_does_not_exist_on_this_network': 'Līguma adrese šajā tīklā nepastāv',
        'error': 'Kļūda',
        'time_hours': 'stundas',
        'time_minute': 'minūtes',
        'time_second': 'sekundes',
        'sellers_address': 'Pārdevēja adrese ir pārbaudīta',
        'stored_table': 'Šeit būs ienākošo darījumu vēsture',
        'history_table': 'Ienākošo darījumu vēsture',
        'full_info': 'Rādīt visu informāciju',
        'table_date': 'datums',
        'table_sum': 'Summa',
        'table_value': 'Valūta',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Summa un datums',
        'payment_partially_paid': 'Daļēji apmaksāts',
        'paid_timer': 'Apmaksāts',
        'expired_timer': 'Maksājuma termiņš ir beidzies',
    },
    'new_checkout': {
        'details': {
            'title': 'Detaļa',
            'order': 'Pasūtījums',
            'date': 'Datums',
            'description': 'Apraksts',
        },
        'notification': 'Lūdzu, nosūtiet tikai <b>{orderCurrency}</b> marķierus <b>{orderNetwork}</b> tīklā uz šo adresi.',
        'payment_address': 'Maksājuma adrese',
        'verified_notification': "Pārdevēja adrese ir pārbaudīta",
        'paid_amount': "Apmaksāta summa",
        'timer_notifications': {
            'remaining_time': 'Atlikušais laiks maksāt:',
            'completed': 'Maksājums ir veiksmīgi pabeigts',
            'expired': 'Maksājuma kalpošanas laiks beidzās',
        },
        'statuses': {
            'not_paid': 'Nav samaksāts',
            'overpaid': 'Pārmaksāts',
            'partially_paid': 'Daļēji apmaksāts',
            'fully_paid': 'Pilnībā apmaksāts',
        },
        'history': {
            'title': 'Maksājumu vēsture',
            'columns': {
                'date': 'Datums',
                'amount': 'Summa',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Visi jūsu darījumi, kas nosūtīti uz norādīto adresi, būs pieejami šajā logā.',
            'ok': 'Labi'
        },
        'cancel': 'Atcelt',
        'back_to_shop': 'Atpakaļ uz veikalu'
    },
    'new_invoice': {
        'details': {
            title: 'Detaļa',
            invoice: 'Rēķins',
            date: 'Datums',
            description: 'Apraksts'
        },
        'amount': 'Summa',
        'btn_label': 'Maksājiet ar {selectedCurrencyNetwork}',
        'btn_label_empty': 'Atlasiet valūtu',
        'cancel': 'Atcelt',
        'search': 'Meklēt...',
    },
    'languages': {
        'select': 'Izvēlēties valodu'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Lapa nav atrasta',
            'page_not_found_text': 'Liekas, ka mēs nevaram atrast meklēto lapu',
        }
    }
};