import { InfoModals } from "./InfoModals";

export const ru = {
    'exit': 'Выход',
    'copy': {
        'id_copied': 'id скопировано',
        'id_not_copied': 'id не скопировано',
        'notification': 'Уведомление',
        'token_copied': 'Токен скопирован',
        'addresses_copied': 'Адрес скопирован',
        'addresses_not_copied': 'Адрес не скопирован',
        'the_name_of_the_key_copied': 'Название ключа скопировано',
        'the_name_of_the_key_not_copied': 'Название ключа не скопировано',
        'the_public_key_copied': 'Публичный ключ скопирован',
        'the_public_key_not_copied': 'Публичный ключ не скопирован',
        'the_private_key_copied': 'Приватный ключ скопирован',
        'the_private_key_not_copied': 'Приватный ключ не скопирован',
        'api_key_successfully_updated': 'Api ключ успешно обновлен',
        'failed_to_update_api_key': 'Не удалось обновить api ключ',
        'order_title': 'Название ордера скопировано',
        'order_title_failed': 'Название ордера не скопировано',
        'transaction_hash': 'Хеш транзакции скопирован',
        'transaction_hash_failed': 'Хеш транзакции не скопирован',
        'link_copied': 'Ссылка скопирована',
        'edit': 'Редактировать',
        'deleteKey': 'Удалить',
    },
    'navigation': {
        'main': 'Главная',
        'advance_balances': 'Авансовые балансы',
        'addresses': 'Кошельки',
        'addresses_sub': 'Pay-in',
        'change': 'Обмены',
        'subscriptions': 'Рекурренты',
        'payment-bundle': 'Платежные связки',
        'payment-address': 'Платежные адреса',
        'organizations': 'Организации',
        'payments': 'Платежи',
        'orders': 'Ордера',
        'transactions': 'Транзакции',
        'withdrawal': 'Вывод',
        'info': 'Информация',
        'api_keys': 'Ключи Api',
        'api_docs': 'Документация Api',
        'donations': 'Донаты',
        'payout_wallet': 'Выплаты',
        'invoice': 'Счета',
        'cross-chain-history': 'Cross Chain History',
        'bridge': 'Мост',
        'business-wallet': 'Бизнес-кошелек',
        'referral': 'Реферальная программа',
        'support': 'Поддержка',
        'address-book': 'Адресная книга',
        'integrations': 'Интеграции',
    },
    'settings-modal': {
        'title': 'Бизнес аккаунт',
        'dark-theme': 'Темная тема',
        'settings': 'Настройки',
        'aml-policy': 'AML политика',
        'business-settings': 'Бизнес настройки',
        'logout': 'Выйти',
        'language': 'Язык'
    },
    'authorization': {
      'login-title': 'Авторизация',
        'registration-title': 'Регистрация',
        'verif-title': 'Верификация',
        'verification-email': 'Подтверждение почты',
        'password': 'Пароль',
        'repeat-password': 'Повторите пароль',
        'email-confirm': 'Подтверждение e-mail',
        'email-description': 'Ссылка для подтверждения регистрации отправлена на почту. Письмо придет с адреса noreply@reg1-ocp.email.',
        'dont-have-acc': 'Вы еще не зарегистрировались?',
        'have-acc': 'Уже есть аккаунт?',
        'ref-code': 'Реферальный код',
        'username': 'Имя пользователя',
        'email': 'E-mail',
        'incorrect-data': 'Неправильные email или пароль',
        'invalid-email': 'Неверный email',
        'invalid-passwords': 'Неверный пароль',
        'invalid-password-not-match': 'Пароли не совпадают',
        'user-existing': 'Пользователь уже существует',
        'send': 'Отправить',
        'logout': 'Выйти',
        'enter-code': 'Ввести код',
        'verification-description': 'Введите код для двухфакторной авторизации или один из 20 запасных',
        'code-not-entered': 'Код не введен',
        'code-not-valid': 'Код не верен',
        'code': 'Код'

    },
    'dashboard': {
        'main': 'Главная',
        'balance': 'Баланс',
        'balances': 'Все балансы',
        'fold': 'Свернуть',
        'orders': 'Ордера',
        'there_is_nothing': 'Ничего нет',
        'period': 'Период',
        'successful_transactions': 'Успешные транзакции',
        'type': 'Тип',
        'quantity': 'Количество',
        'amount': 'Сумма',
        'currency': 'Валюта',
        'deposits': 'Пополнение',
        'withdrawals': 'Выводы',
        'orders_decl': 'Ордеров',
        'created_decl': 'Созданных',
        'executed_decl': 'Выполненных',
        'created': 'Созданые',
        'completed': 'Выполненные',
        'incoming_transactions': 'Входящие транзакции',
        'outgoing_transactions': 'Исходящие транзакции',
        'completed_withdrawal_requests': 'Выполненные заявки на вывод',
        'enable_two-factor_authentication': 'Подключить двухфакторную аутентификацию',
        'enable': 'Подключить',

    },
    'advance_balances': {
        'account_replenished_successfully': "Аккаунт {start} **** {end} был пополнен на {amount} {ticker}",
        'title': 'Авансовые балансы',
        'description': 'Авансовый баланс используется для автоматического списания комиссии за все финансовые операции на сервисе, а также за комиссию сети.',
        'currency': 'Валюта',
        'currency-and-network-label': 'Валюта и сеть',
        'currency-and-network-placeholder': 'Выберите валюту и сеть',
        'currency-and-network-sublabel': 'Ваши монеты будут переданы по обменному курсу',
        'currency-and-network-search': 'Поиск',
        'balance': 'Баланс',
        'no_data_available': 'Нет данных',
        'deposit': 'Пополнить',
        'network': 'Сеть',
        'select_network': 'Выберите сеть',
        'address_for_replenishment': 'Адрес для пополнения:',
        'time': ' Время до истечения адреса:',
        'cancel': 'Закрыть',
        'get_an_address': 'Перейти к оплате',
        'replenishment': 'Пополнение баланса',
        'replenishment_subtitle': 'Для продолжения необходимо выбрать валюту и сеть',
        'unknown': 'Выбрана неизвестная монета для пополнения',
        'payments': 'Прием платежей в данной монете сейчас невозможен',
        'need_to_select': 'Для продолжения необходимо выбрать сеть',
        'creating_an_address': 'Создаем адрес',
        'balance_history': 'История баланса',
        'history_of_actions': 'История действий.',
        'period': 'Период',
        'select_dates': 'Выбрать даты',
        'Replenishment': 'Пополнение',
        'withdrawal': 'Вывод',
        'withdrawal_fee': 'Комиссия вывода',
        'creating_an_order': 'Комиссия за создание ордера',
        'deposit_by_order': 'Прием платежей',
        'deposit_by_order_fee': 'Прием платежей',
        'wallet_withdrawal_fee': 'Вывод с кошелька',
        'recurrent_withdrawal_fee': 'Вывод с адреса-рекуррента',
        'personal_withdrawal_fee': 'Вывод с персонального адреса',
        'payout_withdrawal_fee': 'Выводы с выплатного баланса',
        'wallet_deposit_fee': 'Депозит на кошелек',
        'deposit_by_wallet_fee': 'Депозит на кошелек',
        'recurrent_deposit_fee': 'Депозит на адрес-рекуррент',
        'personal_deposit_fee': 'Депозит на персональный адрес',
        'payout_deposit_fee': 'Пополнение выплатного баланса',
        'exchange_internal_fee': 'Внутренний обмен',
        'exchange_auto_fee': 'Авто обмен',
        'transfer': 'Внутренний перевод',
        'transfer_fee': 'Внутренний перевод',
        'write-downs': 'Списание',
        'cancellation_of_the_operation': 'Отмена операции',
        'bonus_accrual': 'Начисление бонуса',
        'from_address': 'С адреса:',
        'link_to_order': 'Ссылка на ордер',
        'return': 'Вернуться',
        'type_operation': 'Тип операции',
        'select_type': 'Выберите тип',
        'amount_received': 'Полученная сумма',
        'amount': 'Сумма',
        'order': 'Ордер',
        'type_of_operation': 'Тип операции',
        'price': 'Цена',
        'link_to_withdrawal': 'Ссылка на вывод',
        'tariff_description': 'Комиссия за исполненную транзакцию по созданному ордеру',
        'select_currency': 'Выберите валюту',
        'will_be_converted': 'Ваши монеты будут переведены в «{currencyName}» по биржевому курсу.',
        'expire': 'Время до истечения адреса',
        'link': 'Ссылка',
        'btn-cancel': 'Отменить',
        'btn-close': 'Закрыть',
        'btn-continue': 'Продолжить',
        "btn_get-an-address": 'К оплате',
        'replenishment_method': 'Способ пополнения',
        'external_wallet': ' Внешний кошелек',
        'internal_pay-in-balance': 'Pay-in баланс',
        'internal_business-wallet': 'Бизнес кошелек',
        'internal_recurrent-address': 'Рекуррентный адрес',
        'spent_period': 'Потрачено за период',
        'one_day': '24 часа',
        'seven_day': '7 дней',
        'thirty_day': '30 дней',
        'all_period': 'Весь период',
        'choose-deposit-method': 'Выберите способ пополнения',
        'bridge_internal_fee': 'Блокчейн мост',
        'bridge_external_fee': 'Блокчейн мост API',
        'payment-address': 'Адрес оплаты',
        'kyt-transaction-fee': 'Риски транзакции',
        'kyt-withdrawal-address-fee': 'Риски вывода',
        'kyt-transaction': 'Проверка рисков транзакции',
        'kyt-withdrawal-address': 'Проверка рисков вывода',
        'exchange-auto': 'Обмен API',
        'exchange-auto-fee': 'Комиссия за обмен API',
        'timer': {
            'awaiting-payment': 'Ожидание оплаты',
            'payment-lifetime-has-expired': 'Срок оплаты истек'
        },
        'service-commission-amount': 'Service commission amount',
        'blockchain-commission-amount': 'Blockchain Commission amount',
        'commission-source': 'Commission source',
    },
    'bridge': {
        'title': 'Блокчейн мост',
        'description': 'Блокчейн мост для токенов на ваших адресах между сетями.',
        'bridge_source': {
            'label': 'Источник моста',
            'business_wallet': 'Business wallet',
            'payin_wallet': 'Payin wallet',
        },
        'currency_network_select': {
            'label': 'Выберите вашу валюту и сеть',
            'placeholder': 'Выберите вашу валюту и сеть',
        },
        'address_select': {
            'label': 'Выберите свой адрес',
            'placeholder': 'Адрес',
        },
        'amount_input': {
            'label': 'Сумма',
            'available': 'Доступный баланс',
            'minimum': 'Минимум',
            'maximum': 'Максимум',
        },
        'currency_select': {
            'label': 'Выберите валюту',
            'placeholder': 'Валюта',
        },
        'network_select': {
            'label': 'Выберите сеть',
            'placeholder': 'Сеть',
        },
        'amount_to_input': {
            'label': 'Вы получите',
        },
        'footer': {
            'execute_btn': 'Выполнить',
            'source': 'Источник оплаты комиссии',
            'advance_balance': 'Авансовый баланс',
            'service_commission': 'Комиссия сервиса',
        },
        'modals': {
            'execute': {
                'title': 'Подтверждение блокчейн-моста',
                'currency': 'Валюта',
                'network': 'Сеть',
                'amount': 'Количество',
                'address': 'Адрес',
                'source': 'Источник оплаты комиссии',
                'advance_balance': 'Авансовый баланс',
                'service_commission': 'Комиссия сервиса',
                'network_commission': 'Комиссия сети',
                'confirm': 'Подтвердить',
            },
            'create_address': {
                'title': 'Добавление адреса',
                'address': 'Адрес',
                'network': 'Сеть',
                'currency': 'Валюта',
                'note': 'Заметка',
                'note_placeholder': 'Введите текст',
                'comment': 'Комментарий',
                'comment_placeholder': 'Введите комментарий',
                'cancel_btn': 'Отменить',
                'save_btn': 'Сохранить',
                'warning': 'У вас нет адреса в выбранной валюте и сети. Вам нужно создать адрес.',
                'warning_btn': 'Создать адрес',
            },
            'success': {
                'title': 'Блокчейн мост запущен',
                'btn': 'Закрыть',
            },
            'error': {
                'title': 'Произошла ошибка',
                'btn': 'Закрыть'
            }
        },
        'errors': {
            'advance_balance_enough': 'На авансовом балансе',
        },
        'history': {
            'filters': 'Фильтры',
            'copy': {
                'id_copied': 'Id скопирован',
                'id_not_copied': 'Id не скопирован',
            },
            'title': 'История Блокчейн мостов',
            'description': 'Блокчейн мост для токенов на ваших адресах между сетями',
            'currency': 'Валюта',
            'currency_placeholder': 'Выберите валюту',
            'network_from': 'Сеть отправки',
            'network_to': 'Сеть получения',
            'network_placeholder': 'Выберите сеть',
            'period': 'Период',
            'period_placeholder': 'Выберите период',
            'period_select_btn': 'Выбрать даты',
            'status': 'Статус',
            'status_placeholder': 'Выберите статус',
            'statuses': {
                "CREATED": 'Создан',
                "PENDING": 'Обработка',
                "ERROR": 'Ошибка',
                "REJECTED": 'Отклонен',
                "PROCESSED": 'Выполнен',
            },
            'table': {
                'headers': {
                    'id': 'id',
                    'amount': 'Сумма',
                    'date': 'Дата',
                    'state': 'Статус',
                    'currency': 'Валюта',
                    'networkFrom': 'Сеть откуда',
                    'addressFrom': 'Адрес откуда',
                    'networkTo': 'Сеть куда',
                    'addressTo': 'Адрес куда',
                    'networkCommission': 'Комиссия сети',
                    'serviceCommission': 'Комиссия  сервиса',
                },
            },
        },
    },
    'addresses': {
        'title': 'Балансы',
        'button_transfer': 'Перевод',
        'description': 'Кошелек для приема оплаты по ордерам. Доступно сделать выводы прибыли и внутренние транзакции для пополнения авансового баланса.',
        'currency_search': 'Поиск валюты',
        'zero_balances': 'Нулевые балансы',
        'currency': 'Валюта',
        'address': 'Адрес',
        'network': 'Сеть',
        'balance': 'Баланс',
        'withdraw': 'Вывести',
        'deposit': 'Пополнить',
        'no_data_available': 'Нет данных',
        card: {
            title: {
                currency: 'Валюта',
                network: 'Сетей:',
                addresses: 'Адресов:',
                balance: 'Общий баланс:',
            },
        },
    },
    'orders': {
        'account_replenished_successfully': "Аккаунт {start} **** {end} был пополнен на {amount} {ticker}",
        'title': 'Ордера',
        'lifetime': 'Срок действия ордера',
        'lifetimeMinValueMinute': 'от 30 минут',
        'lifetimeMinValueHour': 'от 0 часов | от {n} часа | от {n} часов',
        'lifetimeMaxValueHour': 'до 0 часов | до {n} часа | до {n} часов',
        'description': 'Создание ордеров и отслеживание оплат ордеров.',
        'no_data_available': 'Нет данных',
        'new_order': 'Новый ордер',
        'status': 'Статус',
        'select_the_status': 'Выберите статус',
        'active': 'Активный',
        'in_processing': 'Обработка',
        'partial_payment': 'Частичная оплата',
        'completed': 'Выполнен',
        'expired': 'Истек',
        'with_errors': 'С ошибками',
        'select_currencies': 'Выберите валюту',
        'currencies': 'Валюта',
        'network': 'Сеть',
        'select_network': 'Выберите сеть',
        'period': 'Период',
        'select_dates': 'Выбрать даты',
        'order': 'Ордер',
        'currency': 'Валюта',
        'received_amount': 'Полученная сумма',
        'amount': 'Сумма',
        'date_of_creation': 'Дата создания',
        'error': 'Ошибка',
        'refund': 'Возврат',
        'read_more': 'Подробнее',
        'date_of_creation_order': 'Дата создания',
    },
    'api_documentation': {
        'title': 'Api документация',
        'description': 'Документация API сервиса {productName}.',
    },
    'api_keys': {
        'title': 'Ключи Api',
        'description': 'Доступно добавление нескольких ключей.',
        'no_data_available': 'Нет данных',
        'name': 'Название',
        'public_key': 'Публичный ключ',
        'private_key': 'Приватный ключ',
        'add_api_key': 'Добавить api ключ',
        'search': 'Поиск',
        'api_key_created': ' Api ключ создан',
        'creating_an_api_key': 'Создание api ключа',
        'name_of_the_api_key': ' Название api ключа (alias)',
        'ip_addresses': 'IP адреса',
        'ip_addresses_placeholder': 'Введите IP адреса через пробел',
        'ip_invalid': 'Неверный формат IP адреса',
        'ip_exist': 'Поле ввода IP адреса не должно быть пустым',
        'alias_error': 'Введите название api ключа',
        'be_sure_to_keep': 'Обязательно сохраните себе приватный ключ!',
        'creating_an_api_keys': 'Создание api ключей',
        'cancel': 'Отменить',
        'create': 'Создать',
        'close': 'Закрыть',
        'edit': 'Редактировать',
        'enter_a_new_api_key_name': 'Введите новое название api ключа',
        'save': 'Сохранить',
        'successful_deletion': 'Успешное удаление',
        'deleting_an_api_key': 'Удаление api ключа',
        'are_you_sure': 'Вы уверены что хотите удалить api ключ?',
        'delete': 'Удалить',
    },
    'information': {
        'title': 'Информация',
        'description': 'Комиссия за транзакции в сетях блокчейн.',
        'currency': 'Валюта',
        'network': 'Сеть',
        'commission': 'Комиссия',
        'commission-source': 'Источник комиссии',
        'type': 'Тип',
        'native': 'Нативная',
        'token': 'Токен',
        'withdrawal-amount': 'Сумма вывода',
        'advance-balance': 'Авансовый баланс',
    },
    'transactions': {
        'title': 'Транзакции',
        'description': 'Все ваши транзакции внутри сервиса с полной информацией по каждому движению средств.',
        'no_data_available': 'Нет данных',
        'method': 'Метод заведения',
        'type': 'Тип',
        'basis': 'Основание',
        'source': 'Источник',
        'address_from': 'Адрес откуда',
        'address_to': 'Адрес куда',
        'transfer': 'Перевод',
        'commission-transfer': 'Комиссия за перевод',
        transfer_type: {
            pay_in: 'Входящий баланс',
            pay_out: 'Выплатной баланс',
            advance: 'Авансовый баланс',
        },
        'sum': {
            debit: 'Дебет',
            credit: 'Кредит',
        },
        'basis_enum': {
            'order': 'Ордер',
            'withdrawal': 'Вывод',
            'payment': 'Оплата',
            'refund': 'Возврат',
            'deposit': 'Депозит',
            'transfer': 'Перевод',
            'kyt': 'KYT',
            'exchange-auto': 'Обмен API'
        },
        'source_enum': {
            'internal': 'Внутренний перевод',
            'external': 'Внешний перевод',
        },
        'method_enum': {
            'web': 'Web',
            'api': 'API',
            'pre_checkout': 'Донат',
        },
        'role': 'Ответственный',
        'status': 'Статус',
        'date_of_creation': 'Дата',
        'date_of_creation_order': 'Дата создания ордера',
        'network': 'Сеть',
        'write-off_currency': 'Валюта списания',
        'replenishment_currency': 'Валюта пополнения',
        'coin': 'Монета',
        'order_id': 'id ордера',
        'write-off_amount': 'Сумма списания',
        'deposit_amount': 'Сумма пополнения',
        'commission': 'Комиссия',
        'deposit': 'Пополнение авансового баланса',
        'exchange': 'Обмен валют',
        'network_fee': 'Комиссия сети',
        'orfan_deposit': 'Зачисление',
        'orfan_withdrawal': 'Вывод',
        'commission_create_order': 'Комиссия за создание ордера',
        'commission_execute_order': 'Комиссия за транзакцию по ордеру',
        'commission_wallet_deposit': 'Комиссия за депозит на кошелек',
        'commission_payout_deposit': 'Комиссия за депозит на выплатной баланс',
        'commission_recurrent_deposit': 'Комиссия за депозит на рекуррентный адрес',
        'commission_personal_deposit': 'Комиссия за депозит на персональный адрес',
        'commission_wallet_withdrawal': 'Комиссия за вывод с кошелька',
        'commission_payout_withdrawal': 'Комиссия за вывод с выплатного баланса',
        'commission_recurrent_withdrawal': 'Комиссия за вывод с рекуррентного адреса',
        'commission_personal_withdrawal': 'Комиссия за вывод с персонального адреса',
        'order_transaction': 'Транзакция ордера',
        'deposit_payout_balance': 'Пополнение выплатного баланса',
        'deposit_wallet': 'Пополнение кошелька',
        'deposit_recurrent': 'Пополнение рекуррентного адреса',
        'deposit_personal': 'Пополнение персонального адреса',
        'partners': 'Партнерская программа',
        'referrals': 'Реферальная программа',
        'rolling_reserve': 'Rolling Reserve',
        'withdrawal': 'Вывод',
        'commission_withdrawal': 'Комиссия за вывод',
        'init': 'Обработка',
        'error': 'Ошибка',
        'pending': 'Исполняется',
        'processed': 'Исполнена',
        'executed': 'Исполнена',
        'rejected': 'Отклонена',
        'transaction_type': 'Тип транзакции',
        'select_the_transaction_type': 'Выберите тип транзакции',
        'debiting_currencies': 'Валюта',
        'select_the_debiting_currencies': 'Выберите валюту',
        'replenishment_currencies': 'Валюта пополнения',
        'select_the_replenishment_currencies': 'Выберите валюту пополнения',
        'select_network': 'Выберите сеть',
        'period': 'Период',
        'select_dates': 'Выбрать даты',
        'transaction_type_enum': {
            'commission': 'комиссия',
            'network_fee': 'сетевая плата',
            'deposit': 'депозит',
            'withdrawal': 'вывод',
            'exchange': 'обмен',
            'partners': 'партнеры',
            'referrals': 'рефералы',
            'rolling_reserve': 'подвижной резерв',
            'deposit_payout_balance': 'Выплатной баланс',

        },
        empty: '-',
        'bridge_external': 'Блокчейн мост API',
        'bridge_internal': 'Блокчейн мост ',
        'bridge_internal_fee': 'Комиссия за блокчейн мост',
        'bridge_external_fee': 'Комиссия за блокчейн мост API',
    },
    'withdrawal': {
        'title': 'Вывод',
        'description': 'При создании вывода необходимо указать адрес, с которого будет произведён вывод, также укажите сеть и сумму вывода.',
        'no_data_available': 'Нет данных',
        'status': 'Статус',
        'select_the_status': 'Выберите статус',
        'in_processing': 'В обработке',
        'completed': 'Выполнена',
        'error': 'Ошибка',
        'rejected': 'Отклонена',
        'pending': 'Ожидание',
        'platform': 'Платформа',
        'select_platform': 'Выберите платформу',
        'select_currencies': 'Выберите валюту',
        'currencies': 'Валюта',
        'select_network': 'Выберите сеть',
        'network': 'Сеть',
        'period': 'Период',
        'select_dates': 'Выбрать даты',
        'create_a_withdrawal_request': 'Заявка на вывод',
        'currency': 'Валюта',
        'amount': 'Сумма',
        'date_of_creation': 'Дата создания',
        'date': 'Дата',
    },
    'order': {
        'title': 'Ордер',
        'advance_balance': 'Авансовый баланс:',
        'description': 'Описание:',
        'network': 'Сеть:',
        'currency': 'Валюта:',
        'amount_received': 'Полученная сумма:',
        'amount': 'Сумма:',
        'payment_address': 'Адрес получения:',
        'payment_address_tag': 'Тег адреса оплаты:',
        'link_for_payment': 'Ссылка для оплаты:',
        'date_of_order_creation': 'Дата создания ордера:',
        'validity_period_of_the_order': 'Срок действия ордера:',
        'transactions': 'Транзакции',
        'no_data_available': 'Нет данных',
        'return': 'Вернуться',
        transaction: {
            type_enum: {
                in: 'Пополнение',
                out: 'Вывод',
            },
            currency_type_enum: {
                in: 'Валюта пополнения',
                out: 'Валюта списания',
            },
        },
        empty: '-',
    },
    'transaction': {
        'title': 'Транзакция',
        'return': 'Вернуться',
        'type': 'Тип:',
        'status': 'Статус:',
        'id_in_the_system': 'ID в системе:',
        'order_id': 'ID ордера:',
        'network': 'Сеть:',
        'write-off_currency': 'Валюта списания:',
        'the_tag_of_the_write-off_address': 'Тег адреса списания:',
        'write-off_address': 'Адрес списания:',
        'write-off_amount': 'Сумма списания:',
        'replenishment_currency': 'Валюта зачисления:',
        'the_tag_of_the_enrollment_address': 'Тег адреса зачисления:',
        'transfer_address': 'Адрес получения:',
        'transfer_amount': 'Сумма зачисления:',
        'transfer_amount_USD': 'Сумма зачисления в USD:',
        'transfer_amount_rate': 'Курс к USD:',
        'transfer_recipients': 'Адреса получателей:', confirmations_expected: 'Количество ожидаемых подтверждений:',
        'transfer_senders': 'Адреса отправителей:',
        'transaction_in_the_blockchain': 'Транзакция в блокчейне:',
        'date_of_creation': 'Дата создания:',
        confirmations: 'Подтверждений:',

    },
    'request': {
        'title': 'Заявка на вывод',
        'return': 'Вернуться',
        'currency': 'Валюта:',
        'network': 'Сеть:',
        'status': 'Статус:',
        'amount': 'Сумма:',
        'commission': 'Комиссия:',
        'commission_source': 'Источник комиссии:',
        'sending_address': 'Адрес получения:',
        'tag_of_the_sending_address': 'Тег адреса отправки:',
        'date_of_application_creation': 'Дата создания заявки:',
        'address_balance': 'Баланс адреса',
        'advance_balance': 'Авансовый баланс',
        'init': 'Создан',
        'error': 'Ошибка',
        'processed': 'Успешно',
        'rejected': 'Отклонен',
        'pending': 'В обработке',
        '-': '-',
        'transaction_hash': 'Хэш транзакции',
        'withdrawal_address': 'Адрес списания',
    },

    'create-withdrawal-request-modal': {
        'application_created': 'Заявка создана',
        'creating_application': 'Вывод',
        'output_created': 'Вывод создан',
        'pending': 'Вывод находится в обработке',
        'error': 'Произошла ошибка при создании вывода, обратитесь в поддержку',
        'the_withdrawal_is_completed': 'Вывод исполнен',
        'withdrawal_rejected': 'Вывод отклонен, обратитесь в поддержку',
        'creating_an_application': 'Создание заявки',
        'output_address': 'Адрес, с которого выводить',
        'select_address': 'Выберите адрес',
        'receiving_address': 'Адрес получения',
        'invalid_address': 'Введите корректный адрес',
        'enter_address': 'Введите адрес зачисления',
        'tag': 'Тег:',
        'enter_tag': 'Введите тег, если он есть',
        'amount': 'Сумма:',
        'enter_amount': 'Введите сумму вывода',
        'max': 'МАКС',
        'minimum_withdrawal': 'Минимум к выводу',
        'deposit_withdrawal_addresses_match': 'Адреса получения и вывода не должны совпадать',
        'advance_balance': 'Авансовый баланс',
        'address_balance': 'Баланс адреса',
        'commission_source': 'Источник комиссии',
        'commission': 'Комиссия:',
        'update': 'Обновить комиссии',
        'receive': 'ВЫ ПОЛУЧИТЕ',
        'cancel': 'Отменить',
        'send': 'Отправить',
        'close': 'Закрыть',
        'transaction_hash': 'Хэш транзакции:',
        'Advance_balance': 'авансовый баланс',
        'Address_balance': 'баланс адреса',
        'withdrawal_amount': 'Сумма вывода',
        'available': 'Доступно',
        'service_commission': 'Комиссия сервиса',
        'blockchain_fee': 'Комиссия блокчейн',
        'add_btn': 'Добавить',
        'complete_processed_desc': 'Транзакция отправлена на подтверждение в блокчейне'
    },

    'payout-modal-transfer': {
        'application_created': 'Заявка создана',
        'creating_application': 'Перевод',
        'output_created': 'Перевод создан',
        'pending': 'Перевод находится в обработке',
        'error': 'Произошла ошибка при создании перевод, обратитеть в поддержку',
        'the_withdrawal_is_completed': 'Перевод исполнен',
        'withdrawal_rejected': 'Перевод отклонен, обратитесь в поддержку',
        'creating_an_application': 'Создание заявки',
        'output_address': 'Источник перевода',
        'select_address': 'Выберите адрес',
        'receiving_address': 'Куда перевести:',
        'enter_address': 'Введите адрес зачисления',
        'tag': 'Тег:',
        'enter_tag': 'Введите тег, если он есть',
        'amount': 'Сумма',
        'enter_amount': 'Введите сумму перевода',
        'max': 'МАКС',
        'minimum_withdrawal': 'Минимум к переводу:',
        'current_advance_balance': 'Текущий авансовый баланс',
        'advance_balance': 'Авансовый баланс',
        'advance_balance_after': 'Авансовый баланс после транзакции',
        'address_balance': 'Баланс адреса',
        'service-commission-amount': 'Сумма комиссии сервиса',
        'blockchain-commission-amount': 'Сумма блокчейн комиссии',
        'commission-source': 'Источник комиссии',
        'commission': 'Комиссия',
        'update': 'Обновить Комиссии',
        'receive': 'Вы получите',
        'cancel': 'Отменить',
        'send': 'Отправить',
        'close': 'Закрыть',
        'transaction_hash': 'Хэш транзакции:',
        'Advance_balance': 'авансовый баланс',
        'Address_balance': 'баланс адреса',
        'advance': 'Авансовый баланс',
        'pay-out': 'Выплатной баланс',
        'output': {
            'title-success': 'Успех',
            'title-error': 'Ошибка',
            'subtitle-success': 'Ваш внутренний перевод прошел успешно',
            'subtitle-error': 'Повторите попытку позже или обратитесь в службу поддержки',
        },
    },

    'create-order-modal': {
        'order': 'Ордер',
        'new_order': 'Новый ордер',
        'order_placeholder': 'Ордер №867123567142',
        'description': 'Описание',
        'description_of_order': 'Описание ордера',
        'currency': 'Валюта',
        'network': 'Сеть',
        'amount': 'Сумма',
        'creating_order': 'Создание ордера',
        'cancel': 'Отменить',
        'create': 'Создать',
        'close': 'Закрыть',
        'hours': 'Часы',
        'minutes': 'Минуты',
        'invalid-url': 'Некорректная ссылка',
    },
    'preview-order-in-modal': {
        'address': 'Адрес:',
        'currency': 'Валюта:',
        'network': 'Сеть:',
        'amount': 'Сумма:',
        'valid_until': 'Действителен до:',
        'link': 'Ссылка на страницу оплаты ордера:',
    },
    'checkout': {
        'shop': 'Магазин',
        'payment': 'Оплата',
        'back_to_the_store': 'Вернуться в магазин',
        'to_make_a_payment': 'Для совершения оплаты отправьте «{orderCurrency}» в сети «{orderAlias}» на указанный адрес',
        'payment_completed': 'Платеж исполнен',
        'the_payment_is_invalid': 'Платеж не действителен',
        'payment_rejected': 'Платеж отклонен',
        'payment_pending': 'Платеж в обработке',
        'payment_init': 'Платеж в ожидании',
        'lifetime_has_expired': 'Истекло время жизни платежа. Не отправляйте монеты по указанному адресу.',
        'contact_the_store_owner': 'Истекло время жизни платежа. Не отправляйте монеты по указанному адресу. Свяжитесь с владельцем магазина для возврата монет или продолжения оплаты',
        'order': 'Ордер:',
        'to_be_paid': 'К оплате:',
        'received': 'Получено:',
        'description': 'Описание:',
        'before_the_expiration_of_the_order': 'Cрок действия платежа: ',
        'network': 'Сеть:',
        'payment_address': 'Адрес оплаты:',
        'payment_address_not_found': 'Адрес оплаты не найден',
        'tag': 'Тег:',
        'contract_address': 'Адрес контракта:',
        'send_to_this_address_only': 'Отправляйте на этот адрес только',
        'make_sure_the_network_is_correct': 'Убедитесь, что сеть верна:',
        'network_is_correct': 'Убедитесь, что сеть верна:',
        'contract_address_matches': 'Убедитесь, что адрес контракта совпадает',
        'payment_page': 'Страница оплаты',
        'the_address_was_copied': 'Адрес успешно скопирован',
        'the_tag_was_copied': 'Тег был скопирован',
        'invoice_was_successfully_paid': 'Счет был успешно оплачен',
        'the_invoice_was_paid': 'Счет был оплачен на сумму ',
        'important_info': 'Важная информация',
        'the_contract_address_does_not_exist_on_this_network': 'адрес контракта не существует в этой сети',
        'error': 'Ошибка',
        'time_hours': 'часов',
        'time_minute': 'минут',
        'time_second': 'секунд',
        'sellers_address': 'Адрес продавца верифицирован',
        'stored_table': 'Здесь будет история входящих транзакций',
        'history_table': 'История входящих транзакций',
        'full_info': 'Показать полную информацию',
        'table_date': 'Дата',
        'table_sum': 'Сумма',
        'table_value': 'Валюта',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Сумма и дата',
        'payment_partially_paid': 'Частично оплачен',
        'paid_timer': 'Оплачено',
        'expired_timer': 'Срок действия платежа истек',
    },
    'new_checkout': {
        'details': {
            'title': 'Детали',
            'order': 'Ордер',
            'date': 'Дата',
            'description': 'Описание',
        },
        'notification': 'Пожалуйста, отправляйте только токены <b>{orderCurrency}</b> в сети <b>{orderNetwork}</b> на этот адрес.',
        'payment_address': 'Адрес платежа',
        'verified_notification': "Адрес продавца верифицирован",
        'paid_amount': "Сумма оплаты",
        'timer_notifications': {
            'remaining_time': 'Оставшееся время для оплаты',
            'completed': 'Платеж был успешно завершен',
            'expired': 'Время жизни платежа истекло',
        },
        'statuses': {
            'not_paid': 'Не оплачен',
            'overpaid': 'Переплачен',
            'partially_paid': 'Частично оплачен',
            'fully_paid': 'Полностью оплачен',
        },
        'history': {
            'title': 'История платежей',
            'columns': {
                'date': 'Дата',
                'amount': 'Сумма',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Все ваши транзакции, отправленные на указанный адрес, будут доступны в этом окне.',
            'ok': 'OK'
        },
        'cancel': 'Отменить',
        'back_to_shop': 'Вернуться в магазин',
    },
    'error': {
        'server_error_contact_support': 'Ошибка сервера, обратитесь в службу поддержки.',
        'unknown_error_please_contact_support': 'Неизвестная ошибка, обратитесь в службу поддержки.',
        'it_is_necessary_to_choose_coin': 'Необходимо выбрать монету.',
        'no_coin_specified': 'Не указана монета',
        'you_must_specify_the_amount': 'Необходимо указать сумму',
        'the_amount_is_specified_incorrectly': 'Некорректно указана сумма',
        'the_amount_cannot_be_less_than': 'Сумма не может быть меньше или равна 0',
        'error_webhook_not_specified': 'Не указан Error Webhook',
        'invalid_URL_passed_as_Error_Webhook': 'Некорректный URL передан в качестве Error Webhook',
        'success_Webhook_is_not_specified': 'Не указан Success Webhook',
        'invalid_URL_passed_as_Success_Webhook': 'Некорректный URL передан в качестве Success Webhook',
        'incorrect_expiration_date_of_the_order': 'Некорректная дата истечения ордера',
        'empty_output_list': 'Пустой список на вывод',
        'the_output_address_is_not_specified': 'Не указан адрес для вывода',
        'invalid_output_address': 'Некорректный адрес для вывода',
        'invalid_tag_specified': 'Некорректный тег(memo) указан',
        'the_withdrawal_amount_is_not_specified': 'Не указана сумма вывода',
        'incorrect_withdrawal_amount': 'Некорректная сумма вывода',
        'the_withdrawal_amount_cannot_be_less_than': 'Сумма вывода не может быть меньше или равной 0',
        'an_unknown_coin_is_indicated': 'Указана неизвестная монета',
        'insufficient_funds_for_the_operation': 'Недостаточно средств для операции',
        'the_balance_is_less_than_the_requested_amount': 'Баланс меньше запрошенной суммы',
        'the_output_is_already_running_wait_for_it_to_finish_and_repeat': 'Вывод уже запущен, дождитесь его завершения и повторите',
        'the_debit_coin_is_not_specified': 'Не указана монета списания',
        'incorrect_debit_coin': 'Некорректная монета списания',
        'the_transfer_coin_is_not_specified': 'Не указана монета зачисления',
        'incorrect_crediting_coin': 'Некорректная монета зачисления',
        'the_amount_of_the_charge_is_not_specified': 'Не указана сумма списания',
        'incorrect_debit_amount': 'Некорректная сумма списания',
        'the_debit_amount_cannot_be_less_than': 'Сумма списания не может быть меньше или равна 0',
        'an_unknown_debit_coin_is_indicated': 'Указана неизвестная монета списания',
        'an_unknown_crediting_coin_is_specified': 'Указана неизвестная монета зачисления',
        'order_ID_not_specified': 'Не указан Order ID',
        'invalid_Order_ID': 'Некорректный Order ID',
        'invalid_URL_passed_as_Return_URL': 'Некорректный URL передан в качестве Return URL',
        'the_custom_Order_ID_is_too_long': 'Пользовательский Order ID слишком длинный',
        'payment_description_is_too_long': 'Описание платежа слишком длинной',
        'the_coin_network_is_not_specified': 'Не указана сеть монеты',
        'incorrect_Coin_network': 'Некорректная сеть монеты',
        'the_advance_balance_is_not_specified': 'Не указан авансовый баланс',
        'incorrect_advance_balance': 'Некорректный авансовый баланс',
        'address_not_specified': 'Не указан адрес',
        'invalid_address': 'Некорректный адрес',
        'no_token_specified_for_output': 'Не указан токен для вывода',
        'invalid_token_passed_for_output': 'Передан некорректный токен для вывода',
        'insufficient_funds_on_the_advance_balance': 'Недостаточно средств на авансовом балансе',
        'the_amount_is_less_than_the_minimum': 'Сумма меньше минимальной',
        'incorrect_coin_network': 'Некорректная сеть монеты',
        'the_withdrawal_token_has_expired_repeat_the_request_again': 'Токен для вывода истек, повторите запрос еще раз',
        'withdrawal_token': 'Токен для вывода был создан для другого авансового баланса или адреса',
        'the_exchange_is_prohibited': 'Обмен запрещен',
        'the_coin_is_not_available_for_operations': 'Монета недоступна для операций',
        'at_the_moment_the_withdrawal_is_suspended_in_this_coin': 'В данный момент вывод приостановлены в данной монете',
        'the_network_is_unavailable_for_operations': 'Сеть недоступна для операций',
        'the_network_is_undergoing_technical_work': 'Сеть находиться на технических работах',
        'at_the_moment_the_withdrawal_is_suspended_in_this_network': 'В данный момент вывод приостановлены в данной сети',
        'the_advance_balance_does_not_belong_to_the_current_organization': 'Авансовый баланс не принадлежит текущей организации',
        'the_address_does_not_belong_to_the_current_user': 'Адрес не принадлежит текущему пользователю',
        'the_withdrawal_amount_exceeds_the_available_balance': 'Сумма вывода превышает доступный баланс',
        'error_two-fa': 'Неверный код 2FA',
    },
    '2fa-auth-qrcode-modal': {
        'enable_two-factor_authorization': 'Подключить двухфакторную авторизацию',
        'remember_the_secret_code': 'Запомните секретный код:',
        'connect_two-factor_authentication': 'Подключите двухфакторную аутентификацию с помощью QR-кода или ввести код вручную внутри приложения',
        'enter_code_input-label': 'Введите код аутентификации после подключения',
        'enter_code_input-placeholder': 'Введите код...',
        'save_the_backup_codes_for_authorization': 'Сохраните запасные коды для авторизации',
        'close': 'Закрыть',
        '2FA_Authorization': 'Двухфакторная авторизация',
        'do_you_want_to_enable_two-factor_authorization': 'Хотите подключить двухфакторную авторизацию?',
        'Two-factor_authorization': 'Двухфакторная авторизация',
        'setup_successfully': 'Теперь вы можете использовать код аутентификации 2FA в любое время при входе в {productName}',
        'error_when_setting_up_two-factor_authorization': 'ошибка при настройке двухфакторной авторизации',
        'incorrect_code': 'неправильный код',
        'save_codes': 'Сохраните одноразовые коды, они пригодятся, если возникнут сложности со входом с помощью двухфакторной авторизации',
        'sorry_error': 'Извините что-то пошло не так, обратитесь в службу поддержки.',
        'access_recovery_keys': 'Доступ к ключам восстановления',
        'QR_code': 'QR код',
        'download': 'Скачать',
        'enable': 'Подключить',
        'next': 'Дальше',
        'invalid_code': 'Неверный код',
        'ok': 'OK',
        'success': 'Успешно',
        'success_info': 'Вы успешно подключили двухфакторную авторизацию'
    },
    'donations': {
        'title': 'Донаты',
        'create_donation': 'Создать донат',
        'table_header': {
            'title': 'Название',
            'total_sum_24h': 'Сбор суммы за 24ч',
        },
        'details': 'Подробнее',
        'modal': {
            'title': 'Удаление страницы доната',
            'subtitle': 'Вы действительно хотите удалить',
            'success': 'Донат удален',
        },
    },
    'donation': {
        'title': 'Донат',
        'details': 'Список созданных страниц для сбора средств.',
        'orders': 'Ордера',

    },
    'create-donation': {
        'title': 'Создать донат',
        'title_edit': 'Редактировать донат',
        'upload-photo': 'Загрузить фото',
        'step-1': {title: 'Шаг 1', description: 'Введите ссылку, заголовок, выберите монеты'},
        'step-2': {title: 'Шаг 2', description: 'Подтвердите данные'},
        'inputs': {
            'labels': {
                'alias': 'Название',
                'color': 'Выберите цвет',
                'title': 'Заголовок',
                'description': 'Описание',
                'currency_list': 'Список монет',
                'currency_select_all': 'Выбрать все',
                'currency_list_selected': 'Выбрано',
                'currency_list_selected_out_of': 'из',
            },
            'placeholders': {
                'alias': 'Название',
                'title': 'Введите заголовок',
                'description': 'Введите описание',
            },
            'errors': {
                'title_empty': 'Введите заголовок',
                'currencies_empty': 'Добавьте валюту',
            },
        },
        'button': {
            'create_donation': 'Создать донат',
            'edit_donation': 'Редактировать донат',
            'ok': 'Готово',
            'go_back': 'Вернуться назад',
        },
        'result': {
            'success': {
                'created': 'Вы успешно создали донат',
                'edited': 'Вы успешно отредактировали донат',
            },
            'error': {
                'created': 'Что-то пошло не так. Код ошибки {error}',
                'edited_wrong': 'Что-то пошло не так.',
                'edited_unable': 'Не удалось отредактировать',
                'edited_code': 'Код ошибки {error}',

            },
        },
    },
    'preCheckout': {
        'errors': {
            'unknown_error': 'Неизвестная ошибка',
            'fail_to_create_order': 'Не удалось создать ордер',
            'external_service_error': 'Ошибка внешнего сервиса',
            'token_is_required': 'Токен обязателен',
            'currency_and_network_is_required': 'Валюта и сеть обязательны',
            'invalid_token': 'Некорректный токен',
            'page_not_found': 'Страница не найдена',
            'page_not_found_text': 'Мы не можем найти страницу, которую вы ищете',
            'page_not_accepted_deposits_in_currency': 'Некорректная валюта для этой страницы',
            'currency_not_allowed_for_deposits': 'Эта валюта не поддерживается для данной страницы',
        },
        'inputs': {
            'labels': {
                'amount': 'Сумма',
                'currency': "Валюта",
                'alias': 'Email или имя отправителя',
                'commentary': 'Комментарий',
            },
            'placeholders': {
                'amount': 'Введите сумму',
                'currency': "Выберите валюту",
                'alias': 'Введите email или имя отправителя',
                'commentary': 'Введите комментарий',
            },
        },
        'button': {
            'payment': 'Перейти к оплате',
        },
        footer: {
            product_2022: 'Все права защищены © {productName} 2023',
        },
        'new-tab': 'Страница для оплаты доната откроется в новой вкладке',
        'nothing-happening': 'Если ничего не происходит нажмите',
        here: 'здесь',
    },
    'payout-wallet': {
        title: 'Выплаты',
        subtitle: 'Список выплатных балансов.',
        'button-info': 'Подробнее',
        'button-create-payout': 'Перевод',
        'button-execute-registry': 'Исполнить',
        'button-replenish-payout': 'Пополнить баланс',
        'button-open-csv': 'Создать выплаты',
        'currency': 'Валюта',
        'withdrawal_amount': 'Сумма вывода',
        'fee': {
            'advance': 'Комиссия с авансового баланса',
            'address': 'Комиссия с адресов',
        },
        tab: {
            balance: 'Балансы',
            registry: 'Реестр выплат',
        },

        'balance': {
            'network': 'Сеть',
            'address': 'Адрес',
            'amount': 'Сумма',
            'currency': 'Валюта',
        },
    },
    'upload_registry': {
        'line': 'Строчка',
        'error': 'Ошибка',
        'status': {
            'init': 'Инициализирован',
            'pending': 'В обработке',
            'processed': 'Исполнен',
            'error': 'Ошибка',
            'partial': 'Исполнен частично',
            'unknown': 'Неизвестно',
        },
        'errors': {
            'amount_lte_zero': 'Сумма меньше или равно нулю',
            'incorrect_address': 'Некорректный адрес',
            'incorrect_tag': 'Некорректный тег',
            'currency_not_found': 'Валюта не найдена',
            'fail_to_process_bch': 'Ошибка обработки BCH адреса',
            'payout_address_not_found': 'Выплатной баланс не найден',
            'invalid_delimiter': 'Некорректный разделитель',
            'invalid_amount_of_columns': 'Некорректное количество колонок',
            'required_fields_are_empty': 'Обязательные поля не заполнены',
            'network_not_found': 'Сеть не найдена',
            'unknown_error': 'Неизвестная ошибка',
            'too_many_lines': 'Превышено ограничение количества строк',
        },
    },
    'payout_modal_deposit': {
        'label': 'Монета и сеть',
        'placeholder': 'Выберите валюту и сеть',
        'title': 'Пополнение баланса',
        'subtitle': 'Для продолжения необходимо выбрать валюту и сеть',
        'details': 'Вы можете отправлять сумму в неограниченном количестве',
    },
    'payout_modal_upload': {
        'title': 'Создание выплаты',
        'subtitle': 'Для продолжения необходимо ввести название и загрузить csv файл',
        'label': 'Введите название',
        'placeholder': 'Название',
        'placeholder-file': {
            'uploaded': 'Файл загружен',
            'drag': 'Перетащите файл',
            'press': 'или нажмите сюда',
            'drop': 'Отпустите тут файл',
        },
        'button': {
            'upload': 'Загрузить',
            'cancel': 'Отменить',
            'save': 'Сохранить',
        },
    },
    'payout_modal_execute': {
        'title': 'Выполнить реестр',
        'subtitle': 'Выполнить реестр?',
        'pending': 'Реестр исполняется',
        'success': 'Реестр выполнен',
        'button': {
            'execute': 'Выполнить',
        },
    },
    'donation-order': {
        'title': 'Ордер доната',
        'status': 'Статус',
        'system_id': 'ID в системе',
        'amount': 'Сумма',
        'currency': 'Валюта',
        'network': 'Сеть',
        'orderId': 'ID ордера',
        'pageId': 'ID страницы',
        'nickname': 'Никнейм',
        'message': 'Сообщение',
        'received_usd': 'Получено в USD',
        'received': 'Получено',
        'price_usd': 'Курс к USD',
        'status_enum': {
            'pending': 'В обработке',
            'executed': 'Выполнен',
            'rejected': 'Отменен',
            'error': 'Ошибка',
            'init': 'Активный',
        },

    },
    'invoice': {
        'account_replenished_successfully': "Аккаунт {start} **** {end} был пополнен на {amount} {ticker}",
        'title': 'Счета ',
        'description': 'Создание счета в удобной валюте и отслеживание оплат счетов.',
        'account_number': 'Номер счета',
        'account_amount': 'Сумма счета',
        'payment_currency': 'Валюта оплаты',
        'payment_amount': 'Сумма оплаты',
        'exchange_rate': 'Курс',
        'no_data_available': 'Нет данных',
        'new_bill': 'Новый Счет',
        'status': 'Статус',
        'select_the_status': 'Выберите статус',
        'active': 'Активный',
        'in_processing': 'В обработке',
        'partial_payment': 'Частичная оплата',
        'completed': 'Выполнен',
        'expired': 'Истек',
        'with_errors': 'С ошибками',
        'select_currencies': 'Выберите валюту',
        'currencies': 'Валюта',
        'network': 'Сеть',
        'select_network': 'Выберите сеть',
        'period': 'Период',
        'select_dates': 'Выбрать даты',
        'order': 'Ордер',
        'invoice': 'Счет',
        'currency': 'Валюта',
        'received_amount': 'Полученная сумма',
        'amount': 'Сумма',
        'date_of_creation': 'Дата создания',
        'error': 'Ошибка',
        'refund': 'Возврат',
        'read_more': 'Подробнее',
        'date_of_creation_order': 'Дата создания',
        'return': 'Вернуться',
        'go_to_invoice': 'Перейти к счету',
        'info_network': 'Валюта оплаты и сеть:',
        'info_title': 'Счет №',
        'info_sum': 'Сумма счета:',
        'info_rate': 'Курс:',
        'info_pay_sum': 'Оплаченная сумма:',
        'info_description': 'Описание',
        'info_main_title': 'Счет',
        'invoice_currency': 'Валюта счета',
        'rejected': 'Отменен',
        'created': 'Создан',
        'info_order_amount': 'Сумма ордера:',
        'light': 'Светлая',
        'dark': 'Темная',
        'sum-invoice': 'Сумма счета:',
        'select-invoice': 'Выберите валюту оплаты',
        'coin-network': 'Валюта и сеть:',
        'rate': 'Курс:',
        'sum-payment': 'Сумма оплаты:',
        'button': 'Оплатить',
        'search': 'Поиск',
    },
    'new_invoice': {
        'details': {
            title: 'Подробности',
            invoice: 'Счет',
            date: 'Дата',
            description: 'Описание'
        },
        'amount': 'Количество',
        'btn_label': 'Оплата с {selectedCurrencyNetwork}',
        'btn_label_empty': 'Выберите валюту',
        'cancel': 'Назад',
        'search': 'Поиск...',
    },
    'create-invoice-modal': {
        'invoice': 'Счет',
        'new_invoice': 'Новый счет',
        'invoice_placeholder': 'Счет №867123567142',
        'exchange_rate_percent': '% на курс обмена',
        'checkbox_label': 'Комиссия сети на плательщике',
        'allowable_price': '% допустимый для проскальзывания цены',
        'description': 'Описание',
        'description_of_order': 'Описание счета',
        'currency': 'Валюта',
        'network': 'Сеть',
        'amount': 'Сумма',
        'creating_order': 'Создание счета',
        'cancel': 'Отменить',
        'create': 'Создать',
        'close': 'Закрыть',
        'hours': 'Часы',
        'minutes': 'Минуты',
        'invalid-url': 'Некорректная ссылка',
    },

    'address_book': {
        'title': 'Адресная книга',
        'sub_title': 'Удобное хранение внешних адресов для быстрого вывода.\n',
        'cancel': 'Отменить',
        'create': 'Создать',
        'close': 'Закрыть',
        'edit': 'Редактировать',
        'save': 'Сохранить',
        'deleting_an_book_key': 'Удаление адреса',
        'delete': 'Удалить',
        'are_you_sure': 'Вы действительно хотите удалить адрес',
        'note_line': 'Псевдоним',
        'address_line': 'Адрес',
        'network_line': 'Сеть',
        'button': 'Добавить адрес',
        'add_modal_title': 'Добавление адреса',
        'add_modal_placeholder': 'Введите текст',
        'add_modal_placeholder_address': 'Введите адрес',
        'add_modal_placeholder_network': 'Выберите сеть',
        'add_modal_comment': 'Комментарий (необязательно)',
        'change_modal_title': 'Информация об адресе',
    },
    'history-addresses': {
        'title': 'История адресов',
    },
    'downCommission': {
        'title': 'Хотите снизить комиссии?',
        'description': 'На данный момент для вашего аккаунта установлены максимальные ставки комиссии. Чтобы обсудить индивидуальные условия и снизить комиссию, напишите нам.',
        'confirm': 'Снизить комиссии',
        'cancel': 'Мне не интересно',
        'congratulations': 'Вы создали запрос на снижение комиссии. Мы свяжемся с вами.',
        'ready': 'Готово',
    },
    'historyAddresses': {
        'date_of_creation': 'Дата',
        'method': 'Метод заведения',
        'status': 'Статус',
        'basis': 'Основание',
        'source': 'Источник',
        'coin': 'Валюта',
        'network': 'Сеть',
        'type': 'Тип',
        'name': 'Псевдоним',
        'sum': 'Сумма',
        'transaction': 'Транзакция',
        'from_to': 'Адрес откуда/куда',
        'address': 'Адрес',
        'pending': 'Ожидание',
        'title':  'Транзакция (tx hash)',
        'type_transaction': 'Тип транзакции',
        'write-off_address': 'Адрес откуда',
        'transfer_address': 'Адрес куда',
        'write-off_currency': 'Валюта',
        'executed': 'Выполнен',
        'title_main': 'История адресов',
        'comment': 'Комментарий',
        'information': 'Информация о транзакции',
        'input': 'Ввод',
        'conclusion': 'Вывод',
        'withdrawal': 'Вывод',
        'deposit': 'Ввод',
        'title_main_current': 'История адреса',
        'take_type': 'Выберите тип',
    },
    'business-wallet': {
        title: 'Бизнес-кошелек',
        subtitle: 'Адреса для приема свободных депозитов без ордеров и счетов.',
        'transaction_hash': 'Хэш транзакции:',
        'button-info': 'Подробнее',
        'button-create-payout': 'Перевод',
        'button-execute-registry': 'Исполнить',
        'button-replenish-payout': 'Пополнить баланс',
        'button-open-csv': 'Создать выплаты',
        'currency': 'Валюта',
        'withdrawal_amount': 'Сумма вывода',
        'amount': 'Сумма',
        'address': 'Адрес',
        'pseudo': 'Псевдоним',
        'success': 'Адрес успешно добавлен',
        'success_update': 'Адрес успешно обновлен',
        'currency-and-select': 'Валюта/Сеть',
        'close': 'Закрыть',
        'enter-alias': 'Введите псевдоним',
        'fee': {
            'advance': 'Комиссия с авансового баланса',
            'address': 'Комиссия с адресов',
        },
        tab: {
            balance: 'Балансы',
            registry: 'Реестр выплат',
        },

        'balance': {
            'network': 'Сеть',
            'address': 'Адрес',
            'amount': 'Сумма',
            'currency': 'Валюта',
        },
        'add-address': 'Добавить адрес',
        'comment': 'Комментарий',
        'add-modal': {
            'title': 'Добавление адреса',
            'cancel': 'Отменить',
            'confirm': 'Сохранить',
            'continue': 'Продолжить',
            'creating': 'Добавление адреса',
            'alias-placeholder': 'Введите текст',
            'comment-placeholder': 'Введите комментарий',
        },
        'info-modal-title': 'Информация об адресе',
        'empty-currencies': 'Нет доступных монет',
        'added-address-desc': 'Вы успешно создали адрес получателя для {addressString}',
        'added-address-title': 'Адрес создан',
        'added-address-subtitle': 'Полный адрес',
    },
    'not-enough-advance-balance-modal': {
        'title': 'Вывод недоступен',
        'error-text': 'Сумма авансового баланса отрицательная:',
        'btn-label': 'Авансовый баланс',
    },
    'common': {
        'empty': 'Список пуст',
    },
    'referral': {
        'title': 'Реферальная программа',
        'description': 'Щедрая и прозрачная программа вознаграждения.',
        'active_user': 'Активных: ',
        'zero_user': 'Не активных: ',
        'buffer_balance': 'Буферный баланс',
        'balance': 'Баланс',
        'frozen_balance': 'Замороженный баланс',
        'daily_charges': 'Начислений за 24 часа',
        'reward_balance': 'Баланс вознаграждения',
        'last_reward': 'Последнее вознаграждение',
        'next_reward': 'Следующее вознаграждение',
        'withdrawal': 'Вывод',
        'referral': 'Реферал',
        'curr_month': 'Текущий месяц',
        'total': 'Всего',
        'ref_code_copied': 'Реферальный код скопирован',
        'ref_link_copied': 'Реферальная ссылка скопирована',
        'success-withdraw': 'Вывод успешно проведен',
        'error-withdraw': 'При выводе произошла ошибка. Попробуйте позже.',
    },
    'buffer_balance_history': {
        'title': 'История буферного баланса',
        'merchant': 'Мерчант (email)',
        'select_merchant': 'Выберите мерчант',
        'commission_type': 'Тип комиссии',
        'select_commission_type': 'Выберите тип комиссии',
        'type': 'Тип',
        'select_type': 'Выберите тип',
        'period': 'Период',
        'deposit': 'Начисление',
        'withdrawal': 'Списание',
        'transfer_fee': 'Комиссия за перевод',
        'percent': 'Процент ',
        'sum': 'Сумма',
        'date': 'Дата',
        'empty': 'Нет данных',
        'accrual': 'Начисление',
        'write-downs': 'Списание',
    },
    'referral-commission-type': {
        'deposit_by_order_fee': 'Прием платежей',
        'wallet_withdrawal_fee': 'Вывод с кошелька',
        'payout_withdrawal_fee': 'Вывод с выплатного баланса',
        'deposit_by_wallet_fee': 'Депозит на кошелек',
        'payout_deposit_fee': 'Пополнение выплатного баланса',
        'bridge_external_fee': 'Блокчейн мост API',
        'bridge_internal_fee': 'Блокчейн мост',
        'exchange_internal_fee': 'Обмен',
        'exchange_auto_fee': 'Обмен API',
        'refund': 'Возврат',
        'reward_transfer': 'Вывод вознаграждения',
        'recurrent_deposit_fee': 'Депозит на адрес рекуррента',
        'personal_deposit_fee': 'Депозит на персональный адрес',
        'recurrent_withdrawal_fee': 'Вывод с адреса рекуррента',
        'personal_withdrawal_fee': 'Вывод с персонального адреса',

    },
    'reward_balance_history': {
        'title': 'История баланса вознаграждения',
        'sum': 'Сумма',
        'date': 'Дата',
        'type': 'Тип',
        'select_type': 'Выберите тип',
        'address': 'Адрес',
        'transaction': 'Транзакция',
        'address-copied': 'Адрес скопирован',
        'transaction-copied': 'Хеш транзакции скопирован',
    },
    'create-subscription': {
        'title': 'Новый мерчант',
        'name': 'Название',
        'image-link': 'Или ссылка на изображение',
        'upload': 'Загрузить фото',
        'allowFreeSpend': 'Доступно использование методов рекуррентов',
        'maxSpendAmountUSD': 'Сумма списания',
        'approveAmountUSD': 'Сумма апрува',
        'interval': 'Частота вызовов',
        'restrictions': 'Данные по ограничениям',
        'week': 'Неделя',
        'day': 'День',
        'month': 'Месяц',
        'quater': 'Квартал',
        'year': 'Год',
        'update': 'Сохранить',
        'enter_name': 'Введите имя',
        'enter_link': 'Введите ссылку на изображение',
    },
    'subscriptions': {
        'title': 'Платежные связки',
        'description': 'Для приема рекуррентных платежей создайте мерчанта.',
        'second-description': 'Список и история платежных связок плательщиков.',
        'create-merchant': 'Создать мерчанта',
        'create-bundle': 'Создать платежную связку',
        'search-label': 'Email или Ex ID',
        'email': 'Email',
        'token': 'Токен',
        'currency': 'Валюта',
        'status': 'Статус',
        'address': 'Адрес',
        'network': 'Сеть',
        'enter_email': 'Введите email или EX ID',
        'success': 'Активна',
        'pending': 'Обработка',
        'blocked': 'Заблокирована',
        'canceled': 'Отменена',
        'declined': 'Отклонена',

    },
    'upload-avatar': {
        'title': 'Загрузка изображения',
        'upload_description': 'Вы можете загрузить изображение в формате JPEG, PNG, WebP, GIF',
        'max_file_size': 'Максимально допустимый размер файла 5 мб',
        'choose_file': 'Выберите файл',
    },
    'create-bundle': {
        'title': 'Создание платежной связки',
        'externalId': 'Внешний ID плательщика',
        'email': 'Email подписчика',
        'enter_email': 'Введите email',
        'enter_external': 'Введите внешний id',
    },
    'subscriptions-addresses': {
        'title': 'Платежные адреса',
        'description': 'Адреса для приема рекуррентных платежей.\n',
    },
    'organization': {
        'organizations_title': 'Организация',
        'organizations_description': 'Управление организацией, пользователями и ролями доступа.',
        'organization': 'Организации',
        'organization_users': 'Пользователи',
        'organization_user': 'Пользователь',
        'organization_roles': 'Роли',
        'organization_role': 'Роль',
        'organization_last_login': 'Последний визит',
        'organization_btn_add': 'Добавить',
        'organization_btn_save': 'Сохранить',
        'organization_btn_create': 'Создать',
        'organization_btn_back': 'Вернуться',
        'organization_company_name': 'Название',
        'organization_enter_title': 'Введите название',
        'organization_user_and_role': 'Пользователь и роль',
        'organization_information_role': ' Информация о роли',
        'organization_comment': 'Комментарий',
        'address_book': 'Адресная книга',
        'advanced_balance': 'Авансовый Баланс',
        'api_keys': 'Ключи API',
        'donations': 'Донаты',
        'invoices': 'Счета',
        'orders': 'Ордера',
        'payout_balances': 'Выплаты',
        'transactions': 'Транзакции',
        'wallets_business': 'Бизнес-кошелек',
        'wallets_crosschain_swap': 'Обмены',
        'wallets_crosschain_transfer': 'Мост',
        'wallets_payin': 'Pay-in',
        'organization_enter_input': 'Введите данные',
        'organization_enter_email': 'Введите email',
        'organization_enter_name': 'Введите имя',
        'organization_enter_comment': 'Введите комментарий',
        'modal_user': 'Новый пользователь',
        'modal_create_user': 'Ссылка для нового пользователя успешно создана',
        'modal_name': 'Имя',
        'modal_take_role': 'Выберите роль',
        'modal_email': 'Email',
        'modal_link_invite': 'Ссылка для приглашения',
        'modal_btn_invite': 'Пригласить',
        'modal_btn_close': 'Закрыть',
        'modal_link_copy': 'Ссылка скопирована',
        'modal_delete_role': 'Удаление роли',
        'modal_delete_user': 'Удаление пользователя',
        'modal_delete_message': 'Вы действительно хотите удалить роль',
        'modal_delete_message_user': 'Вы действительно хотите удалить пользователя',
        'modal_delete_info': 'Информация о пользователе',
        'organization_no_user_found': 'Пользователь не найден или не зарегистрирован',
        'organization_user_already_in': 'Пользователь уже состоит в этой организации',
        'recurrent': 'Рекурренты',
        'personal': 'Персональные адреса',
    },
    'info_modals': InfoModals,
    'rules': {
        'not_allowed': 'Нет прав доступа к разделу',
    },
    'kyc-modal': {
        'title': 'Предупреждение!',
        'description': 'Уважаемый клиент, обратите внимание, что в настоящее время наш сервис недоступен для использования без прохождения обязательной процедуры KYC/KYB, тем не менее, вы можете перейти на эту веб-страницу, чтобы более подробно ознакомиться с тем, что мы можем вам предложить. Принимая это предупреждение, вы подтверждаете свою осведомленность о текущих требованиях KYC/KYB. Чтобы завершить нашу процедуру KYB, свяжитесь с нами по электронной почте или Telegram.',
    },
    'languages': {
        'select': 'Выберите язык'
    },
    'two-fa-restrictions': {
        'title': 'Действие недоступно',
        'enableTwoFA': 'Включить 2FA',
        'cancel': 'Отменить',
        'description': 'Действие недоступно без двухфакторной аутентификации.'
    },
    'two-fa-confirmation': {
        'title': 'Двухфакторная аутентификация',
        'input-label': 'Введите код для подтверждения действия.',
        'input-placeholder': 'Введите код 2FA',
        'cancel': 'Отменить',
        'next': 'Далее',
        'error_length': 'Код должен быть не менее 6 символов',
        'error_letter': 'Код должен быть не менее 6 символов',
        'error_two-fa': 'Неверный код 2FA',
    }
};
