export const bg = {
    'invoice': {
        'invoice': 'Номер на сметка:',
        'sum-invoice': 'Сума по сметката:',
        'select-invoice': 'Изберете валута на плащане',
        'coin-network': 'Валута и мрежа:',
        'rate': 'Добре:',
        'sum-payment': 'Сума за плащане:',
        'button': 'Плащане',
        'search': 'Търсене',
    },
    'checkout': {
        'shop': 'Резултат',
        'payment': 'Плащане',
        'back_to_the_store': 'Обратно към магазина',
        'to_make_a_payment': 'Изпратете «{orderCurrency}» в мрежата «{orderAlias}» , за да извършите плащане до посочения адрес',
        'payment_completed': 'Плащането е извършено',
        'the_payment_is_invalid': 'Плащането не е валидно',
        'payment_rejected': 'Плащането е отхвърлено',
        'payment_pending': 'Плащането е в процес на обработка',
        'payment_init': 'Изчакващо плащане',
        'lifetime_has_expired': 'Срокът на действие на плащането е изтекъл. Не изпращайте монети на посочения адрес.',
        'contact_the_store_owner': 'Срокът на действие на плащането е изтекъл. Не изпращайте монети на посочения адрес. Свържете се със собственика на магазина, за да върнете монети или да продължите плащането',
        'order': 'Поръчка',
        'to_be_paid': 'Да платя',
        'received': 'получено:',
        'description': 'Описание:',
        'before_the_expiration_of_the_order': 'Срок на валидност на плащането:',
        'network': 'Нетно:',
        'payment_address': 'Адрес за плащане:',
        'payment_address_not_found': 'Адресът за плащане не е намерен',
        'tag': 'Етикет:',
        'contract_address': 'Адрес на договора:',
        'send_to_this_address_only': 'Изпращайте само на този адрес',
        'make_sure_the_network_is_correct': 'Проверете дали мрежата е правилна:',
        'network_is_correct': 'Проверете дали мрежата е правилна:',
        'contract_address_matches': 'Уверете се, че адресът на договора съвпада',
        'payment_page': 'Страница за плащане',
        'the_address_was_copied': 'Адресът е копиран успешно',
        'the_tag_was_copied': 'Етикетът е копиран',
        'invoice_was_successfully_paid': 'Поръчката е платена успешно',
        'the_invoice_was_paid': 'Поръчката е платена',
        'important_info': 'Важна информация',
        'the_contract_address_does_not_exist_on_this_network': 'Адресът на договора не съществува в тази мрежа',
        'error': 'Грешка',
        'time_hours': 'часа',
        'time_minute': 'минути',
        'time_second': 'секунди',
        'sellers_address': 'Адресът на продавача е потвърден',
        'stored_table': 'Тук ще бъде историята на входящите транзакции',
        'history_table': 'История на входящите транзакции',
        'full_info': 'Покажи пълните подробности',
        'table_date': 'датата',
        'table_sum': 'Сума',
        'table_value': 'Валута',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Сума и дата',
        'payment_partially_paid': 'Частково сплачено',
        'paid_timer': 'Платено',
        'expired_timer': 'Плащането е изтекло',
    },
    'new_checkout': {
        'details': {
            'title': 'Подробности',
            'order': 'Поръчка',
            'date': 'Дата',
            'description': 'Описание',
        },
        'notification': 'Моля, изпратете само <b>{orderCurrency}</b> жетони в мрежата на <b>{orderNetwork}</b> на този адрес.',
        'payment_address': 'Адрес за плащане',
        'verified_notification': "Адресът на продавача е проверен",
        'paid_amount': "Платена сума",
        'timer_notifications': {
            'remaining_time': 'Оставащо време за плащане:',
            'completed': 'Плащането е успешно завършено',
            'expired': 'Животът на плащането изтече',
        },
        'statuses': {
            'not_paid': 'Неплатен',
            'overpaid': 'Надплатен',
            'partially_paid': 'Частично платени',
            'fully_paid': 'Напълно платен',
        },
        'history': {
            'title': 'История на плащанията',
            'columns': {
                'date': 'Дата',
                'amount': 'Количество',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Всички ваши транзакции, изпратени до посочения адрес, ще бъдат достъпни в този прозорец.',
            'ok': 'Добре'
        },
        'cancel': 'Отказ',
        'back_to_shop': 'Обратно към магазина',
    },
    'new_invoice': {
        'details': {
            title: 'Подробности',
            invoice: 'Фактура',
            date: 'Дата',
            description: 'Описание'
        },
        'amount': 'Количество',
        'btn_label': 'Платете с {selectedCurrencyNetwork}',
        'btn_label_empty': 'Изберете валута',
        'cancel': 'Отказ',
        'search': 'Търсене...',
    },
    'languages': {
        'select': 'Избери език'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Страницата не е намерена',
            'page_not_found_text': 'Не можем да намерим страницата, която търсите',
        }
    }
};