import { InfoModals } from "./InfoModals";

export const en = {
    'exit': 'Log out',
    'copy': {
        'id_copied': 'id copied',
        'id_not_copied': 'id not copied',
        'notification': 'Notification',
        'token_copied': 'Token copied',
        'addresses_copied': 'Address copied',
        'addresses_not_copied': 'Addresses not copied',
        'the_name_of_the_key_copied': 'The name of the key copied',
        'the_name_of_the_key_not_copied': 'The name of the key not copied',
        'the_public_key_copied': 'The public key copied',
        'the_public_key_not_copied': 'The public key not copied',
        'the_private_key_copied': 'Private key copied',
        'the_private_key_not_copied': 'The private key not copied',
        'api_key_successfully_updated': 'Api key successfully updated',
        'failed_to_update_api_key': 'Failed to update api key',
        'order_title': 'Order title  copied',
        'order_title_failed': 'Order title not copied',
        'transaction_hash': 'Transaction copied',
        'link_copied': 'Link copied',
        'transaction_hash_failed': 'Transaction hash not copied',
        'edit': 'Edit',
        'deleteKey': 'Remove',
    },
    'navigation': {
        'main': 'Main',
        'advance_balances': 'Advance balances',
        'addresses': 'Wallets',
        'addresses_sub': 'Pay-in',
        'change': 'Swap',
        'organizations': 'Organizations',
        'subscriptions': 'Recurrents',
        'payment-bundle': 'Payment bundles',
        'payment-address': 'Payment addresses',
        'orders': 'Orders',
        'transactions': 'Transactions',
        'payments': 'Payments',
        'withdrawal': 'Withdrawal',
        'info': 'Information',
        'api_keys': 'Api keys',
        'api_docs': 'Api docs',
        'donations': 'Donations',
        'payout_wallet': 'Payout',
        'invoice': 'Invoices',
        'bridge': 'Bridge',
        'business-wallet': 'Business wallet',
        'referral': 'Referral program',
        'support': 'Support',
        'integrations': 'Integrations',
        'address-book': 'Address book',
    },
    'settings-modal': {
        'title': 'Business account',
        'dark-theme': 'Dark theme',
        'settings': 'Settings',
        'aml-policy': 'AML policy',
        'business-settings': 'Business settings',
        'logout': 'Log Out',
        'language': 'Language'
    },
    'authorization': {
        'login-title': 'Authorization',
        'registration-title': 'Registration',
        'verif-title': 'Verification',
        'verification-email': 'Email verification',
        'password': 'Password',
        'repeat-password': 'Repeat password',
        'email-confirm': 'Email confirmation',
        'email-description': 'A link to confirm registration has been sent to the mail.\n' +
            'The email will come from noreply@reg1-ocp.email.',
        'dont-have-acc': 'Don`t you have registered yet?',
        'have-acc': 'Have an account?',
        'ref-code': 'Referral code',
        'username': 'Username',
        'email': 'E-mail',
        'incorrect-data': 'Incorrect email address or password',
        'invalid-email': 'Incorrect email',
        'invalid-passwords': 'Incorrect password',
        'invalid-password-not-match': 'Passwords not match',
        'user-existing': 'User is existing',
        'send': 'Send',
        'logout': 'Log out',
        'enter-code': 'Enter code',
        'verification-description': 'Enter the code for two-factor authorization or one of the 20 spare',
        'code-not-entered': 'The code is not entered',
        'code-not-valid': 'The code is not valid',
        'code': 'Code'
    },
    'dashboard': {
        'main': 'Main',
        'balance': 'Balance',
        'balances': 'Balances',
        'fold': 'Fold',
        'orders': 'Orders',
        'there_is_nothing': 'There is nothing',
        'period': 'Period',
        'successful_transactions': 'Successful transactions',
        'type': 'Type',
        'quantity': 'Quantity',
        'amount': 'Amount',
        'currency': 'Currency',
        'deposits': 'Deposit',
        'withdrawals': 'Withdrawals',
        'orders_decl': 'Orders',
        'created_decl': 'Created',
        'executed_decl': 'Completed',
        'created': 'Created',
        'completed': 'Completed',
        'incoming_transactions': 'Incoming transactions',
        'outgoing_transactions': 'Outgoing transactions',
        'completed_withdrawal_requests': 'Completed withdrawal requests',
        'enable_two-factor_authentication': 'Enable two-factor authentication',
        'enable': 'Enable',
    },
    'advance_balances': {
        'account_replenished_successfully': 'Account {start}****{end} was replenished by {amount} {ticker}',
        'title': 'Advance balances',
        'description': 'The advance balance is used for automatic deduction of commission for all financial transactions on the service, as well as for network fees.',
        'currency': 'Currency',
        'currency-and-network-label': 'Currency and network',
        'currency-and-network-sublabel': 'Your coins will be transferred at the exchange rate',
        'currency-and-network-placeholder': 'Select currency and network',
        'currency-and-network-search': 'Search',
        'balance': 'Balance',
        'no_data_available': 'No data available',
        'deposit': 'Deposit',
        'network': 'Network',
        'select_network': 'Select network',
        'address_for_replenishment': 'Address for replenishment:',
        'time': 'Time until the address expires:',
        'cancel': 'Close',
        'replenishment': 'Balance replenishment',
        'replenishment_subtitle': 'To continue, you need to select the currency and network',
        'unknown': 'An unknown coin has been selected for replenishment',
        'payments': 'Accepting payments in this coin is currently impossible',
        'need_to_select': 'To continue, you need to select a network',
        'creating_an_address': 'Creating an address',
        'balance_history': 'Balance history',
        'history_of_actions': 'History of actions.',
        'period': 'Period',
        'select_dates': 'Select dates',
        'Replenishment': 'Replenishment',
        'withdrawal': 'Withdrawal',
        'withdrawal_fee': 'Withdrawal fee',
        'creating_an_order': 'Order creation fee',
        'deposit_by_order': 'Accepting payments',
        'wallet_withdrawal_fee': 'Withdrawal from wallet',
        'recurrent_withdrawal_fee': 'Withdrawals from recurrent',
        'personal_withdrawal_fee': 'Personal address withdrawal',
        'payout_withdrawal_fee': 'Withdrawals from pay-out',
        'wallet_deposit_fee': 'Wallet deposit',
        'recurrent_deposit_fee': 'Recurrent deposit',
        'personal_deposit_fee': 'Personal address deposit',
        'payout_deposit_fee': 'Deposit pay-out',
        'deposit_by_order_fee': 'Order deposit fee',
        'deposit_by_wallet_fee': 'Wallet deposit fee',
        'exchange_internal_fee': 'Exchange internal fee',
        'exchange_auto_fee': 'Exchange auto fee',
        'transfer': 'Internal transfer',
        'transfer_fee': 'Internal transfer',
        'write-downs': 'Write-downs',
        'cancellation_of_the_operation': 'Cancellation of the operation',
        'bonus_accrual': 'Bonus accrual',
        'from_address': 'From address',
        'link_to_order': 'Link to the order',
        'return': 'Back',
        'type_operation': 'Type of operation',
        'select_type': 'Select type',
        'amount_received': 'Amount received',
        'amount': 'Amount',
        'order': 'Order',
        'type_of_operation': 'Type of operation',
        'price': 'Price',
        'link_to_withdrawal': 'Link to the withdrawal',
        'tariff_description': 'Commission for the executed transaction on the created order',
        'select_currency': 'Select currency',
        'will_be_converted': 'Your coins will be converted to «{currencyName}» at the exchange rate.',
        'expire': 'Address expires in',
        'link': 'Link',
        'btn-cancel': 'Cancel',
        'btn-close': 'Close',
        'btn-continue': 'Continue',
        'btn_get-an-address': 'To payment',
        'replenishment_method': 'Method of deposit',
        'external_wallet': 'External wallet',
        'internal_pay-in-balance': 'Pay-in balance',
        'internal_business-wallet': 'Business Wallet',
        'internal_recurrent-address': 'Recurrent address',
        'spent_period': 'Spent per period',
        'one_day': '24 hours',
        'seven_day': '7 days',
        'thirty_day': '30 days',
        'all_period': 'All period',
        'choose-deposit-method': 'Choose a deposit method',
        'bridge_internal_fee': 'Blockchain bridge',
        'bridge_external_fee': 'Blockchain bridge API',
        'payment-address': 'Payment Address',
        'kyt-transaction-fee': 'Transaction risks',
        'kyt-withdrawal-address-fee': 'Withdrawal risks',
        'kyt-transaction': 'Checking transaction risks',
        'kyt-withdrawal-address':'Checking withdrawal risks',
        'exchange-auto': 'Swap API',
        'exchange-auto-fee': 'Swap API fee',
        'timer': {
            'awaiting-payment': 'Awaiting payment',
            'payment-lifetime-has-expired': 'The payment lifetime has expired'
        }
    },
    'bridge': {
        'title': 'Blockchain Bridge',
        'description': 'Blockchain bridge for tokens on your addresses between networks',
        'bridge_source': {
            'label': 'Bridge source',
            'business_wallet': 'Business wallet',
            'payin_wallet': 'Payin wallet',
        },
        'currency_network_select': {
            'label': 'Choose your currency and network',
            'placeholder': 'Choose your currency and network',
        },
        'address_select': {
            'label': 'Choose your address',
            'placeholder': 'Address',
        },
        'amount_input': {
            'label': 'Amount',
            'available': 'Available balance',
            'minimum': 'Minimum',
            'maximum': 'Maximum',
        },
        'currency_select': {
            'label': 'Choose currency',
            'placeholder': 'Currency',
        },
        'network_select': {
            'label': 'Choose network',
            'placeholder': 'Network',
        },
        'amount_to_input': {
            'label': 'You recieve',
        },
        'footer': {
            'execute_btn': 'Execute',
            'source': 'Source of commission payment',
            'advance_balance': 'Advance balance',
            'service_commission': 'Service commission',
        },
        'modals': {
            'execute': {
                'title': 'Blockchain bridge confirmation',
                'currency': 'Currency',
                'network': 'Network',
                'amount': 'Amount',
                'address': 'Address',
                'source': 'Source of commission payment',
                'advance_balance': 'Advance balance',
                'service_commission': 'Service commission',
                'network_commission': 'Network commission',
                'confirm': 'Confirm'
            },
            'create_address': {
                'title': 'Adding an address',
                'address': 'Address',
                'network': 'Network',
                'currency': 'Currency',
                'note': 'Note',
                'note_placeholder': 'Enter a text',
                'comment': 'Comment',
                'comment_placeholder': 'Enter a comment',
                'cancel_btn': 'Cancel',
                'save_btn': 'Save',
                'warning': 'You do not have an address in the selected currency and network. You need to create an address.',
                'warning_btn': 'Create an address'
            },
            'success': {
                'title': 'Blockchain bridge launched',
                'btn': 'Close'
            },
            'error': {
                'title': 'An error has occurred',
                'btn': 'Close'
            }
        },
        'errors': {
            'advance_balance_enough': 'On advance balance'
        },
        'history': {
            'filters': 'Filters',
            'copy': {
                'id_copied': 'Id copied',
                'id_not_copied': 'Id not copied',
            },
            'title': 'Blockchain bridge history',
            'description': 'Blockchain bridge for tokens on your addresses between networks',
            'currency': 'Сurrency',
            'currency_placeholder': 'Choose currency',
            'network_from': 'Sending network',
            'network_to': 'Receiving network',
            'network_placeholder': 'Choose network',
            'period': 'Period',
            'period_placeholder': 'Choose period',
            'period_select_btn': 'Choose dates',
            'status': 'Status',
            'status_placeholder': 'Choose status',
            'statuses': {
                "CREATED": 'Created',
                "PENDING": 'Pending',
                "ERROR": 'Error',
                "REJECTED": 'Canceled',
                "PROCESSED": 'Completed'
            },
            'table': {
                'headers': {
                    'id': 'id',
                    'amount': 'Sum',
                    'date': 'Date',
                    'state': 'Status',
                    'currency': 'Сurrency',
                    'networkFrom': 'Network from',
                    'addressFrom': 'Address from',
                    'networkTo': 'Network to',
                    'addressTo': 'Address to',
                    'networkCommission': 'Network commission',
                    'serviceCommission': 'Service commission'
                }
            }
        }
    },
    'addresses': {
        'title': 'Balances',
        'button_transfer': 'Transfer',
        'description': 'Wallet for receiving payments for orders. It is possible to withdraw profits and make internal transactions to replenish the advance balance.',
        'currency_search': 'Currency Search',
        'zero_balances': 'Zero balances',
        'currency': 'Currency',
        'address': 'Address',
        'network': 'Network',
        'balance': 'Balance',
        'withdraw': 'Withdraw',
        'deposit': 'Deposit',
        'no_data_available': 'No data available',
        card: {
            title: {
                currency: 'Currency:',
                network: 'Networks:',
                addresses: 'Addresses:',
                balance: 'Total balance:',
            },
        },
    },
    'orders': {
        'title': 'Orders',
        'lifetime': 'Order lifetime',
        'lifetimeMinValueMinute': 'from 30 minutes',
        'lifetimeMinValueHour': 'from 0 hours | from {n} hour | from {n} hours',
        'lifetimeMaxValueHour': 'to 0 hours | to {n} hour | to {n} hours',
        'description': 'Create orders and track order payments.',
        'no_data_available': 'No data available',
        'new_order': 'New order',
        'status': 'Status',
        'select_the_status': 'Select status',
        'active': 'Active',
        'in_processing': 'Processing',
        'partial_payment': 'Partial payment',
        'completed': 'Completed',
        'expired': 'Expired',
        'with_errors': 'With errors',
        'select_currencies': 'Select currency',
        'currencies': 'Currency',
        'network': 'Network',
        'select_network': 'Select network',
        'period': 'Period',
        'select_dates': 'Select dates',
        'order': 'Order',
        'currency': 'Currency',
        'received_amount': 'Received amount',
        'amount': 'Amount',
        'date_of_creation': 'Date of creation',
        'error': 'Error',
        'refund': 'Refund',
        'read_more': 'Read more',
    },
    'api_documentation': {
        'title': 'Api documentation',
        'description': '{productName} API documentation.',
    },
    'api_keys': {
        'title': 'Api keys',
        'description': 'Multiple keys can be added.',
        'no_data_available': 'No data available',
        'name': 'Name',
        'public_key': 'Public key',
        'private_key': 'Private key',
        'add_api_key': 'Add api key',
        'search': 'Search',
        'api_key_created': 'Api key created',
        'creating_an_api_key': 'Creating an api key',
        'name_of_the_api_key': 'Name of the api key (alias)',
        'ip_addresses': 'IP addresses',
        'ip_addresses_placeholder': 'Enter IP addresses separated by a space',
        'ip_invalid': 'Invalid IP address format',
        'ip_exist': 'The IP address input field should not be empty',
        'alias_error': 'Enter alias',
        'be_sure_to_keep': 'Be sure to keep your private key!',
        'creating_an_api_keys': 'Creating an api keys',
        'cancel': 'Cancel',
        'create': 'Create',
        'edit': 'Edit',
        'close': 'Close',
        'enter_a_new_api_key_name': 'Enter a new api key name',
        'save': 'Save',
        'successful_deletion': 'Successful deletion',
        'deleting_an_api_key': 'Deleting an api key',
        'are_you_sure': 'Are you sure you want to delete the api key?',
        'delete': 'Delete',
    },
    'information': {
        'title': 'Information',
        'description': 'Commission for transactions in blockchain networks.',
        'currency': 'Currency',
        'network': 'Network',
        'commission': 'Commission',
        'commission-source': 'Commission source',
        'type': 'Type',
        'native': 'Native',
        'token': 'Token',
        'withdrawal-amount': 'Withdrawal amount',
        'advance-balance': 'Advance balance',
    },
    'transactions': {
        'title': 'Transactions',
        'description': 'All of your transactions within the service are recorded with complete information for each movement of funds.',
        'no_data_available': 'No data available',
        'type': 'Type',
        'method': 'Creation method',
        'basis': 'Basis',
        'source': 'Source',
        'address_from': 'Address from',
        'address_to': 'Address to',
        'sum': {
            debit: 'Debit',
            credit: 'Credit',
        },
        'basis_enum': {
            'order': 'Order',
            'withdrawal': 'Withdrawal',
            'payment': 'Payment',
            'refund': 'Refund',
            'deposit': 'Deposit',
            'transfer': 'Transfer',
            'kyt': 'KYT',
            'exchange-auto': 'Swap API'
        },
        'source_enum': {
            'internal': 'Internal transfer',
            'external': 'External transfer',
        },
        'role': 'Role',
        'status': 'Status',
        'date_of_creation': 'Date',
        'date_of_creation_order': 'Created at',
        'network': 'Network',
        'write-off_currency': 'Write-off currency',
        'replenishment_currency': 'Replenishment currency',
        'coin': 'Coin',
        'order_id': 'Order id',
        'write-off_amount': 'Write-off amount',
        'deposit_amount': 'Deposit amount',
        'commission': 'Commission',
        'deposit': 'Advanced balance replenishment',
        'exchange': 'Currency exchange',
        'network_fee': 'Network commission',
        'orfan_deposit': 'Enrollment',
        'orfan_withdrawal': 'Withdrawal',
        'commission_create_order': 'Order creation fee',
        'commission_execute_order': 'Transaction order fee',
        'commission_wallet_deposit': 'Wallet deposit fee',
        'commission_payout_deposit': 'Payout balance deposit fee',
        'commission_recurrent_deposit': 'Recurrent address deposit fee',
        'commission_personal_deposit': 'Personal address deposit fee',
        'commission_wallet_withdrawal': 'Wallet withdrawal fee',
        'commission_payout_withdrawal': 'Payout balance withdrawal fee',
        'commission_recurrent_withdrawal': 'Recurrent address withdrawal fee',
        'commission_personal_withdrawal': 'Personal address withdrawal fee',
        'order_transaction': 'Order transaction',
        'deposit_payout_balance': 'Payout balance replenishment',
        'deposit_wallet': 'Wallet replenishment',
        'deposit_recurrent': 'Recurrent address replenishment',
        'deposit_personal': 'Personal address replenishment',
        'transfer': 'Transfer',
        'commission-transfer': 'Transfer fee',
        'commission_withdrawal': 'Withdrawal commission',
        'partners': 'Affiliate Program',
        'referrals': 'Referral program',
        'rolling_reserve': 'Rolling Reserve',
        'withdrawal': 'Withdrawal',
        'init': 'Init',
        'error': 'Error',
        'pending': 'Pending',
        'processed': 'Processed',
        'executed': 'Executed',
        'rejected': 'Rejected',
        'transaction_type': 'Transaction type',
        'select_the_transaction_type': 'Select the transaction type',
        'debiting_currencies': 'Currency',
        'select_the_debiting_currencies': 'Select currency',
        'replenishment_currencies': 'Replenishment currency',
        'select_the_replenishment_currencies': 'Select the replenishment currency',
        'select_network': 'Select network',
        'period': 'Period',
        'method_enum': {
            'web': 'Web',
            'api': 'API',
            'pre_checkout': 'Donation',
        },
        transfer_type: {
            pay_in: 'Pay in',
            pay_out: 'Pay out',
            advance: 'Advance Balance',
        },
        'select_dates': 'Select dates',
        'transaction_type_enum': {
            'commission': 'commission',
            'network_fee': 'network fee',
            'deposit': 'deposit',
            'withdrawal': 'withdrawal',
            'exchange': 'exchange',
            'partners': 'partners',
            'referrals': 'referrals',
            'rolling_reserve': 'rolling reserve',
        },
        empty: '-',
        'bridge_external': 'Blockchain brigde API',
        'bridge_internal': 'Blockchain bridge',
        'bridge_internal_fee': 'Blockchain bridge fee',
        'bridge_external_fee': 'Blockchain bridge api fee',
    },
    'withdrawal': {
        'title': 'Withdrawal',
        'description': 'When creating an withdrawal, you must specify the address from which the output will be made, also specify the network and the amount of output.',
        'no_data_available': 'No data available',
        'status': 'Status',
        'select_the_status': 'Select status',
        'in_processing': 'In processing',
        'completed': 'Completed',
        'error': 'Error',
        'rejected': 'Rejected',
        'pending': 'Pending',
        'platform': 'Platform',
        'select_platform': 'Select a platform',
        'select_currencies': 'Select currency',
        'currencies': 'Currency',
        'select_network': 'Select network',
        'network': 'Network',
        'period': 'Period',
        'select_dates': 'Select dates',
        'create_a_withdrawal_request': 'Create a withdrawal',
        'currency': 'Currency',
        'amount': 'Amount',
        'date_of_creation': 'Date of creation',
        'date': 'Date',
    },
    'order': {
        'title': 'Order',
        'advance_balance': 'Advance balance:',
        'description': 'Description:',
        'network': 'Network:',
        'currency': 'Currency:',
        'amount_received': 'Amount received:',
        'amount': 'Amount:',
        'payment_address': 'Receiving address:',
        'payment_address_tag': 'Payment address tag:',
        'link_for_payment': 'Link for payment:',
        'date_of_order_creation': 'Date of order creation:',
        'validity_period_of_the_order': 'Validity period of the order:',
        'transactions': 'Transactions',
        'no_data_available': 'No data available',
        'return': 'Back',
        transaction: {
            type_enum: {
                in: 'Deposit',
                out: 'Withdrawal',
            },
            currency_type_enum: {
                in: 'Deposit currency',
                out: 'Withdrawal currency',
            },
        },
        empty: '-',
    },
    'transaction': {
        'title': 'Transaction',
        'return': 'Back',
        'type': 'Type:',
        'status': 'Status:',
        'id_in_the_system': 'ID in the system:',
        'order_id': 'Order ID:',
        'network': 'Network:',
        'write-off_currency': 'Write-off currency:',
        'the_tag_of_the_write-off_address': 'The tag of the write-off address:',
        'write-off_address': 'Write-off address:',
        'write-off_amount': 'Write-off amount:',
        'replenishment_currency': 'Transfer currency:',
        'the_tag_of_the_enrollment_address': 'The tag of the enrollment address:',
        'transfer_address': 'Receiving address:',
        'transfer_amount': 'Transfer amount:',
        'transfer_amount_USD': 'Transfer amount in USD:',
        'transfer_amount_rate': 'Rate to USD:',
        'transfer_recipients': 'Recipients addresses:',
        'transfer_senders': 'Senders addresses:',
        'transaction_in_the_blockchain': 'Transaction in the blockchain:',
        'date_of_creation': 'Date of creation:',
        confirmations: 'Confirmations:',
        confirmations_expected: 'Confirmations expected:',
    },
    'request': {
        'title': 'Withdrawal',
        'return': 'Back',
        'currency': 'Currency:',
        'network': 'Network:',
        'status': 'Status:',
        'amount': 'Amount:',
        'commission': 'Commission:',
        'commission_source': 'Commission source:',
        'sending_address': 'Receiving address:',
        'tag_of_the_sending_address': 'The tag of the sending address:',
        'date_of_application_creation': 'Date of application creation:',
        'address_balance': 'Address balance',
        'advance_balance': 'Advance balance',
        'init': 'Generated',
        'error': 'Error',
        'processed': 'Successfully',
        'rejected': 'Rejected',
        'pending': 'In processing',
        '-': '-',
        'transaction_hash': 'Transaction hash',
        'withdrawal_address': 'Withdrawal address',
    },

    'create-withdrawal-request-modal': {
        'application_created': 'The application has been created',
        'creating_application': 'Withdrawal',
        'output_created': 'Withdrawal created',
        'pending': 'Withdrawals is being processed',
        'error': 'An error occurred while creating the withdrawal, contact support',
        'the_withdrawal_is_completed': 'The withdrawal is completed',
        'withdrawal_rejected': 'Withdrawal rejected, contact support',
        'creating_an_application': 'Creating an application',
        'output_address': 'Withdrawal address',
        'invalid_address': 'Enter valid address',
        'select_address': 'Select an address',
        'receiving_address': 'Receiving address',
        'enter_address': 'Enter the enrollment address',
        'tag': 'Tag:',
        'enter_tag': 'Enter the tag, if there is one',
        'amount': 'Amount:',
        'enter_amount': 'Enter the withdrawal amount',
        'max': 'MAX',
        'minimum_withdrawal': 'Minimum to the withdrawal',
        'deposit_withdrawal_addresses_match': 'The deposit and withdrawal addresses must not match',
        'advance_balance': 'Advance balance',
        'address_balance': 'Address balance',
        'commission_source': 'Commission source:',
        'commission': 'Commission:',
        'update': 'Update fees',
        'receive': 'YOU WILL RECEIVE:',
        'cancel': 'Cancel',
        'send': 'Send',
        'close': 'Close',
        'transaction_hash': 'Transaction hash:',
        'Advance_balance': 'advance balance',
        'Address_balance': 'address balance',
        'withdrawal_amount': 'Withdrawal amount',
        'available': 'Available',
        'service_commission': 'Service commission',
        'blockchain_fee': 'Blockchain fee',
        'add_btn': 'Add new address',
        'complete_processed_desc': 'Transaction submitted for confirmation in blockchain'
    },

    'payout-modal-transfer': {
        'application_created': 'The application has been created',
        'creating_application': 'Transfer',
        'output_created': 'Transfer created',
        'pending': 'Transfer is being processed',
        'error': 'An error occurred while creating the transfer, contact support',
        'the_withdrawal_is_completed': 'The transfer is completed',
        'withdrawal_rejected': 'Transfer rejected, contact support',
        'creating_an_application': 'Creating an application',
        'output_address': 'Transfer from',
        'select_address': 'Select an address',
        'receiving_address': 'Transfer to:',
        'enter_address': 'Enter the enrollment address',
        'tag': 'Tag:',
        'enter_tag': 'Enter the tag, if there is one',
        'amount': 'Amount',
        'enter_amount': 'Enter the transfer amount',
        'max': 'MAX',
        'minimum_withdrawal': 'Minimum to the transfer:',
        'current_advance_balance': 'Current advance balance',
        'advance_balance': 'Advance balance',
        'advance_balance_after': 'Advance balance after transaction',
        'address_balance': 'Address balance',
        'service-commission-amount': 'Service commission amount',
        'blockchain-commission-amount': 'Blockchain Commission amount',
        'commission-source': 'Commission source',
        'commission': 'Commission',
        'update': 'Update Commissions',
        'receive': 'You will receive',
        'cancel': 'Cancel',
        'send': 'Send',
        'close': 'Close',
        'transaction_hash': 'Transaction hash:',
        'advance': 'Advance balance',
        'pay-out': 'Pay-out balance',
        'output': {
            'title-success': 'Success',
            'title-error': 'Internal transfer failed',
            'subtitle-success': 'Your internal transfer was successful',
            'subtitle-error': 'Please try again later or contact support',
        },
    },
    'create-order-modal': {
        'order': 'Order',
        'new_order': 'New order',
        'order_placeholder': 'Order №867123567142',
        'description': 'Description',
        'description_of_order': 'Description of the order',
        'currency': 'Currency',
        'network': 'Network',
        'amount': 'Amount',
        'creating_order': 'Creating an order',
        'cancel': 'Cancel',

        'create': 'Create',
        'close': 'Close',
        'hours': 'Hours',
        'minutes': 'Minutes',
        'invalid-url': 'Invalid URL',
    },
    'preview-order-in-modal': {
        'address': 'Address:',
        'currency': 'Currency:',
        'network': 'Network:',
        'amount': 'Amount:',
        'valid_until': 'Valid until:',
        'link': 'Link to the order payment page',
    },
    'checkout': {
        'shop': 'Shop',
        'payment': 'Payment',
        'back_to_the_store': 'Back to the store',
        'to_make_a_payment': 'To make a payment, send «{orderCurrency}» in network «{orderAlias}» to the specified address',
        'payment_completed': 'Payment completed',
        'the_payment_is_invalid': 'The payment is invalid',
        'payment_rejected': 'Payment rejected',
        'payment_pending': 'Payment in processing',
        'payment_init': 'Payment pending',
        'lifetime_has_expired': 'The payment lifetime has expired. Do not send coins to the specified address.',
        'contact_the_store_owner': 'The payment lifetime has expired. Do not send coins to the specified address. Contact the store owner for a coin refund or to continue the payment',
        'order': 'Order:',
        'to_be_paid': 'To be paid:',
        'received': 'Received:',
        'description': 'Description:',
        'before_the_expiration_of_the_order': 'Before the expiration of the order: ',
        'network': 'Network:',
        'payment_address': 'Payment address:',
        'payment_address_not_found': 'Payment address not found',
        'tag': 'Tag:',
        'contract_address': 'Contract address:',
        'send_to_this_address_only': 'Send to this address only',
        'make_sure_the_network_is_correct': 'Make sure the network is correct:',
        'network_is_correct': 'Make sure the network is correct:',
        'contract_address_matches': 'Make sure that the contract address matches',
        'payment_page': 'Payment page',
        'the_address_was_copied': 'The address was copied',
        'the_tag_was_copied': 'The tag was copied',
        'invoice_was_successfully_paid': 'The invoice was successfully paid',
        'the_invoice_was_paid': 'The invoice has been paid in the amount of ',
        'important_info': 'Important',
        'the_contract_address_does_not_exist_on_this_network': 'the contract address does not exist on this network',
        'error': 'Error',
        'time_hours': 'hours',
        'time_minute': 'min',
        'time_second': 'sec',
        'sellers_address': 'Sellers address is verified',
        'stored_table': 'Here will be the history of incoming transactions',
        'history_table': 'History of incoming transactions',
        'full_info': 'Show full info',
        'table_date': 'Date',
        'table_sum': 'Amount',
        'table_value': 'Coin',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Amount and Date',
        'payment_partially_paid': 'Payment partially paid',
        'paid_timer': 'Paid',
        'expired_timer': 'Payment has expired',
    },
    'new_checkout': {
        'details': {
            'title': 'Details',
            'order': 'Order',
            'date': 'Date',
            'description': 'Description',
        },
        'notification': 'Please only send <b>{orderCurrency}</b> tokens on the <b>{orderNetwork}</b> network to this address.',
        'payment_address': 'Payment Address',
        'verified_notification': "The seller's address has been verified",
        'paid_amount': "Paid amount",
        'timer_notifications': {
            'remaining_time': 'Remaining time to pay',
            'completed': 'Payment has been successfully completed',
            'expired': 'The payment lifetime has expired',
        },
        'statuses': {
            'not_paid': 'Not Paid',
            'overpaid': 'Overpaid',
            'partially_paid': 'Partially Paid',
            'fully_paid': 'Fully Paid',
        },
        'history': {
            'title': 'Payments history',
            'columns': {
                'date': 'Date',
                'amount': 'Amount',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'All your transactions sent to the specified address will be available in this window.',
            'ok': 'OK'
        },
        'cancel': 'Cancel',
        'back_to_shop': 'Back to shop',
    },
    'error': {
        'server_error_contact_support': 'Server error, contact support.',
        'unknown_error_please_contact_support': 'Unknown error, please contact support.',
        'it_is_necessary_to_choose_coin': 'It is necessary to choose a coin.',
        'no_coin_specified': 'No coin specified',
        'you_must_specify_the_amount': 'You must specify the amount',
        'the_amount_is_specified_incorrectly': 'The amount is specified incorrectly',
        'the_amount_cannot_be_less_than': 'The amount cannot be less than or equal to 0',
        'error_webhook_not_specified': 'Error Webhook not specified',
        'invalid_URL_passed_as_Error_Webhook': 'Invalid URL passed as Error Webhook',
        'success_Webhook_is_not_specified': 'Success Webhook is not specified',
        'invalid_URL_passed_as_Success_Webhook': 'Invalid URL passed as Success Webhook',
        'incorrect_expiration_date_of_the_order': 'Incorrect expiration date of the order',
        'empty_output_list': 'Empty output list',
        'the_output_address_is_not_specified': 'The output address is not specified',
        'invalid_output_address': 'Invalid output address',
        'invalid_tag_specified': 'invalid tag(memo) specified',
        'the_withdrawal_amount_is_not_specified': 'The withdrawal amount is not specified',
        'incorrect_withdrawal_amount': 'Incorrect withdrawal amount',
        'the_withdrawal_amount_cannot_be_less_than': 'The withdrawal amount cannot be less than or equal to 0',
        'an_unknown_coin_is_indicated': 'An unknown coin is indicated',
        'insufficient_funds_for_the_operation': 'Insufficient funds for the operation',
        'the_balance_is_less_than_the_requested_amount': 'The balance is less than the requested amount',
        'the_output_is_already_running_wait_for_it_to_finish_and_repeat': 'The output is already running, wait for it to finish and repeat',
        'the_debit_coin_is_not_specified': 'The debit coin is not specified',
        'incorrect_debit_coin': 'Incorrect debit coin',
        'the_transfer_coin_is_not_specified': 'The transfer coin is not specified',
        'incorrect_crediting_coin': 'Incorrect crediting coin',
        'the_amount_of_the_charge_is_not_specified': 'The amount of the charge is not specified',
        'incorrect_debit_amount': 'Incorrect debit amount',
        'the_debit_amount_cannot_be_less_than': 'The debit amount cannot be less than or equal to 0',
        'an_unknown_debit_coin_is_indicated': 'An unknown debit coin is indicated',
        'an_unknown_crediting_coin_is_specified': 'An unknown crediting coin is specified',
        'order_ID_not_specified': 'Order ID not specified',
        'invalid_Order_ID': 'Invalid Order ID',
        'invalid_URL_passed_as_Return_URL': 'Invalid URL passed as Return URL',
        'the_custom_Order_ID_is_too_long': 'The custom Order ID is too long',
        'payment_description_is_too_long': 'Payment description is too long',
        'the_coin_network_is_not_specified': 'The coin network is not specified',
        'incorrect_Coin_network': 'Incorrect Coin network',
        'the_advance_balance_is_not_specified': 'The advance balance is not specified',
        'incorrect_advance_balance': 'Incorrect advance balance',
        'address_not_specified': 'Address not specified',
        'invalid_address': 'Invalid address',
        'no_token_specified_for_output': 'No token specified for output',
        'invalid_token_passed_for_output': 'Invalid token passed for output',
        'insufficient_funds_on_the_advance_balance': 'Insufficient funds on the advance balance',
        'the_amount_is_less_than_the_minimum': 'The amount is less than the minimum',
        'incorrect_coin_network': 'Incorrect Coin network',
        'the_withdrawal_token_has_expired_repeat_the_request_again': 'The withdrawal token has expired, repeat the request again',
        'withdrawal_token': 'The withdrawal token was created for another advance balance or address',
        'the_exchange_is_prohibited': 'The exchange is prohibited',
        'the_coin_is_not_available_for_operations': 'The coin is not available for operations',
        'at_the_moment_the_withdrawal_is_suspended_in_this_coin': 'At the moment, the withdrawal is suspended in this coin',
        'the_network_is_unavailable_for_operations': 'The network is unavailable for operations',
        'the_network_is_undergoing_technical_work': 'The network is undergoing technical work',
        'at_the_moment_the_withdrawal_is_suspended_in_this_network': 'At the moment, the withdrawal is suspended in this network',
        'the_advance_balance_does_not_belong_to_the_current_organization': 'The advance balance does not belong to the current organization',
        'the_address_does_not_belong_to_the_current_user': 'The address does not belong to the current user',
        'the_withdrawal_amount_exceeds_the_available_balance': 'The withdrawal amount exceeds the available balance',
        'error_two-fa': '2FA code is incorrect',
    },
    '2fa-auth-qrcode-modal': {
        'enable_two-factor_authorization': 'Enable Two-factor authorization',
        'remember_the_secret_code': 'Remember the secret code:',
        'connect_two-factor_authentication': 'Connect two-factor authentication using a QR code or insert the code manually inside the application',
        'enter_code_input-label': 'Enter the authentication code after connecting',
        'enter_code_input-placeholder': 'Enter the code...',
        'save_the_backup_codes_for_authorization': 'Save the backup codes for authorization',
        'close': 'Close',
        '2FA_Authorization': 'Two-factor authorization',
        'do_you_want_to_enable_two-factor_authorization': 'Do you want to enable two-factor authorization?',
        'Two-factor_authorization': 'Two-factor authorization',
        'two-factor authorization has been created': 'two-factor authorization has been created',
        'setup_successfully': 'Now you can use 2FA authentication code any time you log in to {productName}',
        'error_when_setting_up_two-factor_authorization': 'error when setting up two-factor authorization',
        'incorrect_code': 'incorrect code',
        'save_codes': 'Save one-time codes, they will be useful to you if there are difficulties with logging in using two-factor authorization',
        'sorry_error': 'Sorry something went wrong, contact support.',
        'access_recovery_keys': 'Access recovery keys',
        'QR_code': 'QR Code',
        'download': 'Download',
        'enable': 'Enable',
        'next': 'Next',
        'invalid_code': 'Invalid code',
        'ok': 'OK',
        'success': 'Success',
        'success_info': 'You have successfully passed two-factor authorization'
    },
    'donation': {
        'title': 'Donation',
        'details': 'List of pages for donations.',
        'orders': 'Orders',
    },
    'donations': {
        'title': 'Donations',
        'create_donation': 'Create donation',
        'table_header': {
            'title': 'Title',
            'total_sum_24h': 'Amount in 24h',
        },
        'details': 'Details',
        'modal': {
            'title': 'Delete donation page',
            'subtitle': 'Do you want to delete',
            'success': 'Successfully deleted',
        },
    },
    'create-donation': {
        'title': 'Create donation',
        'title_edit': 'Edit donation',
        'upload-photo': 'Upload photo',
        'step-1': {title: 'Step 1', description: 'Enter URL, title, select coins'},
        'step-2': {title: 'Step 2', description: 'Confirm and submit'},
        'inputs': {
            'labels': {
                'alias': 'Alias',
                'color': 'Choose color',
                'title': 'Title',
                'description': 'Description',
                'currency_list': 'Coin list',
                'currency_select_all': 'Select all',
                'currency_list_selected': 'Selected',
                'currency_list_selected_out_of': 'out of',
            },
            'placeholders': {
                'alias': 'alias',
                'title': 'Enter title',
                'description': 'Enter description',
            },
            'errors': {
                'title_empty': 'Enter title',
                'currencies_empty': 'Add currency',
            },
        },
        'button': {
            'create_donation': 'Create donation',
            'edit_donation': 'Edit donation',
            'ok': 'Ок',
            'go_back': 'Go back',
        },
        'result': {
            'success': {
                'created': 'Donation page has been successfully created',
                'edited': 'Donation page has been successfully edited',
            },
            'error': {
                'created': 'Something went wrong. Error code {error}',
                'edited_wrong': 'Something went wrong.',
                'edited_unable': 'Unable to edit',
                'edited_code': 'Error code {error}',
            },
        },
    },
    'preCheckout': {
        'errors': {
            'unknown_error': 'Unknown error',
            'fail_to_create_order': 'Fail to create order',
            'external_service_error': 'External service error',
            'token_is_required': 'Token is required',
            'currency_and_network_is_required': 'Currency and network is required',
            'invalid_token': 'Invalid token',
            'page_not_found': 'Page not found',
            'page_not_found_text': 'We can’t seem to find the page you’re looking for',
            'page_not_accepted_deposits_in_currency': 'Currency not accepted for this page',
            'currency_not_allowed_for_deposits': 'Currency not allowed for deposits',
        },
        'new-tab': 'Page for donations payment will be opened in new tab',
        'nothing-happening': 'If nothing happens, press',
        here: 'here',
        'inputs': {
            'labels': {
                'amount': 'Amount',
                'currency': "Currency",
                'alias': 'Email or sender name',
                'commentary': 'Commentary',
            },
            'placeholders': {
                'amount': 'Enter amount',
                'currency': "Choose currency",
                'alias': 'Enter email or sender name',
                'commentary': 'Enter message',
            },
        },
        'button': {
            'payment': 'Go to payment',
        },
        footer: {
            product_2022: 'All rights reserved © {productName} 2023',
        },
    },
    'payout-wallet': {
        title: 'Payout',
        subtitle: 'Payout balances list.',
        'button-create-payout': 'Transfer',
        'button-info': 'Details',
        'button-execute-registry': 'Execute',
        'button-replenish-payout': 'Replenish balance',
        'button-open-csv': 'Create registry',
        'currency': 'Currency',
        'withdrawal_amount': 'Withdrawal amount',
        'fee': {
            'advance': 'Advance balance fee',
            'address': 'Address fee',
        },
        'balance': {
            'network': 'Network',
            'address': 'Address',
            'amount': 'Amount',
            'currency': 'Currency',
        },
        tab: {
            balance: 'Balances',
            registry: 'Registries',
        },
    },
    'upload_registry': {
        'line': 'Line',
        'error': 'Error',
        'status': {
            'init': 'Initialized',
            'pending': 'Pending',
            'processed': 'Executed',
            'error': 'Error',
            'partial': 'Partially executed',
            'unknown': 'Unknown',
        },
        'errors': {
            'amount_lte_zero': 'Amount is less or equal zero',
            'incorrect_address': 'Incorrect address',
            'incorrect_tag': 'Incorrect tag',
            'currency_not_found': 'Currency not found',
            'fail_to_process_bch': 'Fail to process BCH',
            'payout_address_not_found': 'Payout address not found',
            'invalid_delimiter': 'Invalid delimiter',
            'invalid_amount_of_columns': 'Invalid amount of columns',
            'required_fields_are_empty': 'Required fields are empty',
            'network_not_found': 'Network not found',
            'unknown_error': 'Unknown error',
            'too_many_lines': 'Lines limit exceeded',
        },
    },
    'payout_modal_deposit': {
        'label': 'Currency and network',
        'placeholder': 'Select network and currency',
        'title': 'Replenish balance',
        'subtitle': 'Select network and currency to continue',
        'details': 'You can send any amount',
    },
    'payout_modal_upload': {
        'title': 'Create registry',
        'subtitle': 'To continue, enter title select the file to upload',
        'label': 'Enter title',
        'placeholder': 'Title',
        'placeholder-file': {
            'uploaded': 'File uploaded',
            'drag': 'Drag file here',
            'press': 'or press here',
            'drop': 'Drop file here',
        },
        'button': {
            'upload': 'Upload',
            'cancel': 'Cancel',
            'save': 'Save',
        },
    },
    'payout_modal_execute': {
        'title': 'Execute registry',
        'subtitle': 'Execute registry?',
        'pending': 'Registry is pending',
        'success': 'Registry executed successfully',
        'button': {
            'execute': 'Execute',
        },
    },
    'donation-order': {
        'title': 'Donation order',
        'status': 'Status',
        'system_id': 'System ID',
        'amount': 'Amount',
        'currency': 'Currency',
        'network': 'Network',
        'orderId': 'Order ID',
        'pageId': 'Page ID',
        'nickname': 'Nickname',
        'message': 'Message',
        'received_usd': 'Received in USD',
        'received': 'Received',
        'price_usd': ' USD rate',
        'status_enum': {
            'pending': 'Pending',
            'executed': 'Executed',
            'error': 'Error',
            'rejected': 'Rejected',
            'init': 'Active',
        },

    },
    'invoice': {
        'title': 'Invoices ',
        'description': 'Creating an account in a convenient currency and tracking invoice payments.',
        'sum-invoice': 'Invoice amount:',
        'select-invoice': 'Choose payment currency',
        'coin-network': 'Currency and network:',
        'rate': 'Rate:',
        'sum-payment': 'Payment amount:',
        'button': 'Payment',
        'light': 'Light',
        'dark': 'Dark',
        'account_number': 'Invoice id',
        'account_amount': 'Invoice amount',
        'payment_currency': 'Payment currency',
        'payment_amount': 'Payment amount',
        'exchange_rate': 'Rate',
        'no_data_available': 'No data available',
        'new_bill': 'New Invoice',
        'status': 'Status',
        'select_the_status': 'Choose status',
        'active': 'Active',
        'in_processing': 'In process',
        'partial_payment': 'Partial payment',
        'completed': 'Completed',
        'expired': 'Expired',
        'with_errors': 'With errors',
        'select_currencies': 'Select currencies',
        'currencies': 'Currency',
        'network': 'Network',
        'select_network': 'Select network',
        'period': 'Period',
        'select_dates': 'Select dates',
        'order': 'Order',
        'invoice': 'Invoice',
        'currency': 'Currency',
        'received_amount': 'Received amount',
        'amount': 'Amount',
        'date_of_creation': 'Date of creation',
        'error': 'Error',
        'refund': 'Refund',
        'read_more': 'Read more',
        'date_of_creation_order': 'Date of creation',
        'return': 'Return',
        'go_to_invoice': 'Go to invoice',
        'info_network': 'Payment currency and network:',
        'info_title': 'Invoice №',
        'info_sum': 'Invoice amount:',
        'info_rate': 'Rate:',
        'info_pay_sum': 'Payment amount:',
        'info_description': 'Description',
        'info_main_title': 'Invoice',
        'invoice_currency': 'Invoice currency',
        'rejected': 'rejected',
        'created': 'created',
        'info_order_amount': 'Order amount:',
        'search': 'Search',
    },
    'new_invoice': {
        'details': {
            title: 'Details',
            invoice: 'Invoice',
            date: 'Date',
            description: 'Description'
        },
        'amount': 'Amount',
        'btn_label': 'Pay with {selectedCurrencyNetwork}',
        'btn_label_empty': 'Select currency',
        'cancel': 'Return',
        'search': 'Search...',
    },
    'create-invoice-modal': {
        'invoice': 'Invoice',
        'new_invoice': 'New invoice',
        'invoice_placeholder': 'invoice №867123567142',
        'exchange_rate_percent': '% exchange rate',
        'checkbox_label': 'Network commission on the payer',
        'allowable_price': '% allowable for price slippage',
        'description': 'Description',
        'description_of_order': 'Invoice description',
        'currency': 'Currency',
        'network': 'Network',
        'amount': 'Amount',
        'creating_order': 'Creating invoice',
        'cancel': 'Cancel',
        'create': 'Create',
        'close': 'Close',
        'hours': 'Hours',
        'minutes': 'Minutes',
        'invalid-url': 'Invalid link',
    },

    'address_book': {
        'title': 'Address book',
        'sub_title': 'Convenient storage of external addresses for quick access.',
        'cancel': 'Cancel',
        'create': 'Create',
        'close': 'Close',
        'edit': 'Edit',
        'save': 'Save',
        'deleting_an_book_key': 'Deleting an address',
        'delete': 'Delete',
        'are_you_sure': 'Are you sure you want to delete the address',
        'note_line': 'Alias',
        'address_line': 'Address',
        'network_line': 'Network',
        'button': 'Add address',
        'add_modal_title': 'Adding an address',
        'add_modal_placeholder': 'Enter text',
        'add_modal_comment': 'Comment (optional)',
        'change_modal_title': 'Address Information',
        'add_modal_placeholder_address': 'Enter address',
        'add_modal_placeholder_network': 'Choose a network',
    },
    'downCommission': {
        'title': 'Want to lower your fees?',
        'description': 'There are currently maximum commission rates set for your account. To discuss individual conditions and reduce the commission, write to us.',
        'confirm': 'Reduce commissions',
        'cancel': 'I am not interested',
        'congratulations': 'You have created a commission reduction request. We will contact you.',
        'ready': 'Ready',
    },
    'history-addresses': {
        'title': 'История адресов',
    },
    'historyAddresses': {
        'date_of_creation': 'Date',
        'method': 'Institution Method',
        'status': 'Status',
        'basis': 'Base',
        'source': 'Source',
        'coin': 'Currency',
        'network': 'Network',
        'type': 'Type',
        'name': 'Alias',
        'sum': 'Sum',
        'transaction': 'Transaction',
        'from_to': 'Address from/to',
        'address': 'Address',
        'pending': 'Expectation',
        'title':  'Transaction (tx hash)',
        'type_transaction': 'Transaction type',
        'write-off_address': 'Address from',
        'transfer_address': 'Address to',
        'write-off_currency': 'Currency',
        'executed': 'Completed',
        'title_main': 'Addresses history',
        'comment': 'Comment',
        'information': 'Transaction Information',
        'input': 'from',
        'conclusion': 'to',
        'withdrawal': 'Withdrawal',
        'deposit': 'Deposit',
        'title_main_current': 'Address history',
        'take_type': 'Select type',
    },
    'business-wallet': {
        title: 'Business wallet',
        subtitle: 'Addresses for receiving free deposits without orders and accounts.',
        'transaction_hash': 'Transaction hash:',
        'button-info': 'More',
        'button-create-payout': 'Transfer',
        'button-execute-registry': 'Execute',
        'button-replenish-payout': 'Replenish',
        'button-open-csv': 'Create csv',
        'currency': 'Currency',
        'withdrawal_amount': 'Withdrawal amount',
        'amount': 'Amount',
        'address': 'Address',
        'pseudo': 'Alias',
        'success': 'Address has been successfully added',
        'success_update': 'Address has been successfully updated',
        'currency-and-select': 'Currency/Network',
        'close': 'Close',
        'enter-alias': 'Enter alias',
        'fee': {
            'advance': 'Commission from the advance balance',
            'address': 'Commission from the addresses',
        },
        tab: {
            balance: 'Balances',
            registry: 'Register of payments',
        },

        'balance': {
            'network': 'Network',
            'address': 'Address',
            'amount': 'Amount',
            'currency': 'Currency',
        },
        'add-address': 'Add address',
        'comment': 'Comment',
        'add-modal': {
            'title': 'Adding an address',
            'cancel': 'Cancel',
            'confirm': 'Save',
            'continue': 'Continue',
            'creating': 'Adding an address',
            'alias-placeholder': 'Enter the text',
            'comment-placeholder': 'Enter the comment',
        },
        'info-modal-title': 'Address info',
        'empty-currencies': 'No available currencies',
        'added-address-desc': 'You have successfully created a receiving address for {addressString}',
        'added-address-title': 'Address created',
        'added-address-subtitle': 'Full address',
    },
    'not-enough-advance-balance-modal': {
        'title': 'Withdraw is not available',
        'error-text': 'The amount of the advance balance is negative:',
        'btn-label': 'Advance balance',
    },
    'common': {
        'empty': 'Empty',
    },
    'referral': {
        'title': 'Referral program',
        'description': 'Generous and transparent reward program.',
        'active_user': 'Active: ',
        'zero_user': 'Zero: ',
        'buffer_balance': 'Buffer balance',
        'balance': 'Balance',
        'frozen_balance': 'Frozen balance',
        'daily_charges': 'Charges for 24 hours',
        'reward_balance': 'Reward balance',
        'last_reward': 'Last reward',
        'next_reward': 'Next reward',
        'withdrawal': 'Withdrawal',
        'referral': 'Referral',
        'curr_month': 'Current month',
        'total': 'Total',
        'ref_code_copied': 'Referral code copied',
        'ref_link_copied': 'Referral link copied',
        'success-withdraw': 'The withdrawal was successfully completed.',
        'error-withdraw': 'An error occurred during the output. Try again later.',
    },
    'buffer_balance_history': {
        'title': 'History buffer balance',
        'merchant': 'Merchant (email)',
        'select_merchant': 'Select merchant',
        'commission_type': 'Type of commission',
        'select_commission_type': 'Select commission type',
        'type': 'Type',
        'select_type': 'Select type',
        'period': 'Period',
        'deposit': 'Deposit',
        'withdrawal': 'Withdrawal',
        'transfer_fee': 'Transfer fee',
        'percent': 'Percent',
        'sum': 'Sum',
        'date': 'Date',
        'empty': 'No data',
        'accrual': 'Deposit',
        'write-downs': 'Withdrawal',
    },
    'referral-commission-type': {
        'deposit_by_order_fee': 'Accepting payments',
        'wallet_withdrawal_fee': 'Withdrawal from wallet',
        'payout_withdrawal_fee': 'Withdrawal from payout',
        'deposit_by_wallet_fee': 'Wallet deposit',
        'payout_deposit_fee': 'Deposit payout',
        'bridge_external_fee': 'Blockchain bridge API',
        'bridge_internal_fee': 'Blockchain bridge',
        'exchange_internal_fee': 'Swap',
        'exchange_auto_fee': 'Swap API',
        'refund': 'Refund',
        'reward_transfer': 'Reward transfer',
        'recurrent_deposit_fee': 'Recurrent deposit fee',
        'recurrent_withdrawal_fee': 'Recurrent withdrawal fee',
        'personal_deposit_fee': 'Personal address deposit fee',
        'personal_withdrawal_fee': 'Personal address withdrawal fee',
    },
    'reward_balance_history': {
        'title': 'Reward balance history',
        'sum': 'Sum',
        'date': 'Date',
        'type': 'Type',
        'select_type': 'Select type',
        'address': 'Address',
        'transaction': 'Transaction',
        'address-copied': 'Address copied',
        'transaction-copied': 'Transaction hash copied',
    },
    'create-subscription': {
        'title': 'New merchant',
        'name': 'Name',
        'image-link': 'Or a link to an image',
        'upload': 'Upload image',
        'maxSpendAmountUSD': 'Write-off amount',
        'approveAmountUSD': 'Confirmed amount',
        'allowFreeSpend': 'The use of recurrent methods is available',
        'interval': 'Minimum debiting interval',
        'restrictions': 'Data on restrictions',
        'week': 'Week',
        'day': 'Day',
        'month': 'Month',
        'quarter': 'Quarter',
        'year': 'Year',
        'update': 'Save',
        'enter_name': 'Enter name',
        'enter_link': 'Enter link to an image',
    },
    'subscriptions': {
        'title': 'Payment bundles',
        'description': 'To receive recurring payments, create a merchant account.',
        'second-description': 'List and history of payer payment links.',
        'create-bundle': 'Create payment bundle',
        'create-merchant': 'Create merchant',
        'search-label': 'Email or Ex ID',
        'email': 'Email',
        'token': 'Token',
        'currency': 'Currency',
        'status': 'Status',
        'address': 'Address',
        'network': 'Network',
        'enter_email': 'Enter email or EX ID',
        'success': 'Active',
        'pending': 'Pending',
        'blocked': 'Blocked',
        'canceled': 'Canceled',
        'declined': 'Declined',
    },
    'upload-avatar': {
        'title': 'Upload image',
        'upload_description': 'You can upload an image in JPEG, PNG, Wbmp, GIF format',
        'max_file_size': 'The maximum allowed file size is 5 MB',
        'choose_file': 'Select a file',
    },
    'create-bundle': {
        'title': 'Create payment bundle',
        'externalId': 'Payer`s external id',
        'email': 'Subscriber`s email',
        'enter_email': 'Enter email',
        'enter_external': 'Enter external id',
    },
    'subscriptions-addresses': {
        'title': 'Payment addresses',
        'description': 'Addresses for accepting recurring payments.',
    },
    'organization': {
        'organizations_title': 'Organization',
        'organizations_description': 'Management of organization, users, and access roles.',
        'organization': 'Organizations',
        'organization_users': 'Users',
        'organization_user': 'User',
        'organization_roles': 'Roles',
        'organization_role': 'Role',
        'organization_last_login': 'Last login',
        'organization_btn_add': 'Add',
        'organization_btn_save': 'Save',
        'organization_btn_create': 'Create',
        'organization_btn_back': 'Back',
        'organization_company_name': 'Name',
        'organization_enter_title': 'Enter name',
        'organization_user_and_role': 'User and role',
        'organization_information_role': 'Role information',
        'organization_comment': 'Comment',
        'address_book': 'Address book',
        'advanced_balance': 'Advanced balance',
        'api_keys': 'Api keys',
        'donations': 'Donations',
        'invoices': 'Invoices',
        'orders': 'Orders',
        'payout_balances': 'Payout',
        'transactions': 'Transactions',
        'wallets_business': 'Business wallet',
        'wallets_crosschain_swap': 'Swap',
        'wallets_crosschain_transfer': 'Bridge',
        'wallets_payin': 'Pay-in',
        'organization_enter_input': 'Enter',
        'organization_enter_email': 'Enter email',
        'organization_enter_name': 'Enter name',
        'organization_enter_comment': 'Enter comment',
        'modal_user': 'New User',
        'modal_create_user': 'The link for the new user has been successfully created',
        'modal_name': 'Name',
        'modal_take_role': 'Choose role',
        'modal_email': 'Email',
        'modal_link_invite': 'Invite link',
        'modal_btn_invite': 'Invite',
        'modal_btn_close': 'Close',
        'modal_link_copy': 'Link copied',
        'modal_delete_role': 'Delete role',
        'modal_delete_user': 'Delete user',
        'modal_delete_message': 'Do you really want to delete the role',
        'modal_delete_message_user': 'Do you really want to delete the user',
        'modal_delete_info': 'User information',
        'organization_no_user_found': 'User not found or not registered',
        'organization_user_already_in': 'The user is already a member of this organization',
        'recurrent': 'Recurrents',
        'personal': 'Personal addresses',
    },
    'rules': {
        'not_allowed': 'No access rights to the section',
    },
    'kyc-modal': {
        'title': 'Warning note!',
        'description': 'Dear Client, kindly note that currently our service is not available for use without passing the mandatory KYC/KYB procedure, still you may navigate this webpage to have a closer look at what we may offer you. By accepting this warning you confirm your awareness of current KYC/KYB requirements.\n' +
            'To complete our KYB procedure, please reach out to us via Email or Telegram.',
    },
    'info_modals': InfoModals,
    'languages': {
        'select': 'Select language'
    },
    'two-fa-restrictions': {
        'title': 'Action  not available',
        'enableTwoFA': 'Enable 2FA',
        'cancel': 'Cancel',
        'description': 'Action not available without  two-factor authentication.'
    },
    'two-fa-confirmation': {
        'title': 'Two-factor authentication',
        'input-label': 'Enter the code to confirm the action.',
        'input-placeholder': 'Enter 2FA code',
        'cancel': 'Cancel',
        'error_length': 'Code must be 6 characters',
        'error_two-fa': '2FA code is incorrect',
        'next': 'Next'
    }
};
